import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AfairsSettingRoutingModule } from './afairs-setting-routing.module';
import { AfairsSettingComponent } from './afairs-setting.component';
import { PersonalSettingComponent } from './personal-setting/personal-setting.component';
import { InsuranceSettingComponent } from './insurance-setting/insurance-setting.component';
import { TaxSettingComponent } from './tax-setting/tax-setting.component';
import { SharedModule } from 'src/app/shared/modules/shared/shared.module';
import { AfairsTabComponent } from './afairs-tab/afairs-tab.component';
import { AddEditInsuranceSettingComponent } from './insurance-setting/add-edit-insurance-setting/add-edit-insurance-setting.component';
import { AddEditPersonalSettingComponent } from './personal-setting/add-edit-personal-setting/add-edit-personal-setting.component';
import { AddEditTaxSettingComponent } from './tax-setting/add-edit-tax-setting/add-edit-tax-setting.component';


@NgModule({
  declarations: [
    AfairsSettingComponent,
    PersonalSettingComponent,
    InsuranceSettingComponent,
    TaxSettingComponent,
    AfairsTabComponent,
    AddEditInsuranceSettingComponent,
    AddEditPersonalSettingComponent,
    AddEditTaxSettingComponent
  ],
  imports: [
    CommonModule,
    AfairsSettingRoutingModule,
    SharedModule
  ],
  exports:[
    InsuranceSettingComponent,
    PersonalSettingComponent,
    TaxSettingComponent
  ]
})
export class AfairsSettingModule { }
