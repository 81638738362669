import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-afairs-setting',
  templateUrl: './afairs-setting.component.html',
  styleUrls: ['./afairs-setting.component.scss']
})
export class AfairsSettingComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {}
  
}
