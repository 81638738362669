import { Component,OnInit,ViewChild,ViewEncapsulation,Input } from '@angular/core';
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils"
import { DxReportViewerComponent } from 'devexpress-reporting-angular';
import { AsyncExportApproach } from "devexpress-reporting/scopes/reporting-viewer-settings"
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-viewer',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './report-viewer.component.html',
  styleUrls: [
    "../../../../../../node_modules/devextreme/dist/css/dx.common.css",
    "../../../../../../node_modules/devexpress-richedit/dist/dx.richedit.css",
    "../../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css",
    "../../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.material.blue.light.css",
    "../../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css",
    "../../../../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css",
    "../../../../../../node_modules/devexpress-reporting/dist/css/dx-reportdesigner.css",
    './report-viewer.component.scss',
    
  ]
})
export class ReportViewerComponent  implements OnInit {
  @ViewChild(DxReportViewerComponent, { static: false }) viewer: DxReportViewerComponent;
  title = 'DXReportDesignerSample';
  @Input() reportUrl = "Default";
  // If you use the ASP.NET Core backend:
  invokeAction: string = '/DXXRDV';
  // The backend application URL.
  hostUrl: string = environment.apiRoot;
 
  header:any=`Bearer ${sessionStorage.getItem('token')}`

 language = window.localStorage.getItem('Language') || 'en';

print() {
    this.viewer.bindingSender.Print();
}
ngOnInit(): void {

}

OnParametersInitialized(e:any){
console.log(e.args.ActualParametersInfo)
}


  constructor() { 
    ajaxSetup.ajaxSettings = { headers: {Authorization:this.header,'Accept-Language': this.language} };
    AsyncExportApproach(true);
  }

  submitParameter(filters:any,reporturl1:string) {
    console.log(filters);
    console.log(reporturl1);
    
    debugger
    var result = "";
     if(filters != null)
     {
       result = '?' + new URLSearchParams(filters).toString();
    }
     else{
       result = ""
     }
     console.log(this.viewer.bindingSender)
      this.viewer.bindingSender.OpenReport(reporturl1 + result);
      
   }

   Sendmail(){
  
  var d =  this.viewer.bindingSender.ExportTo('HTML');
    var h = this.viewer.bindingSender.GetReportPreview();
    console.log(d);
   }

}
