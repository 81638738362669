<div class="publicsetting"  style="padding: 0rem 3rem;">
    <h2 class="content-block title  animate__animated animate__fadeIn
">{{'navigationMenu.title'|translate}}</h2>
    <dx-data-grid class="dx-card wide-card"
      [dataSource]="customDataSource"
      [showBorders]="false"
      [focusedRowEnabled]="true"
      [focusedRowIndex]="0"
     [allowColumnResizing]="true"
  [columnAutoWidth]="true"
      [columnHidingEnabled]="true"
      [showColumnLines]="false"
      [rowAlternationEnabled]="true"
      [showRowLines]="false"
      [showBorders]="false"
      [rtlEnabled]="currentLang=='ar'?true:false"
      (onEditingStart)="updateRow($event)"
      (onInitNewRow)="updateRow($event)"
      (onEditorPreparing)="onEditorPreparing($event)"  
      >
    
        <dxo-editing
          mode="popup"
          [useIcons]="false"
          [allowAdding]="false"
          [allowDeleting]="false"
          [allowUpdating]="true"
          [texts]="button"
          >
    <dxo-popup
    title="{{'tableinfo.info'|translate}}" 
    [showTitle]="true"
    [width]="700"
    [height]="525"
    [hideOnOutsideClick]="true"
  >
  </dxo-popup>
  </dxo-editing>
    
    
       <dxo-paging [pageSize]="10"> </dxo-paging>
  <dxo-pager
    [visible]="true"
    allowedPageSizes="true"
    displayMode="full"
    [showPageSizeSelector]="true"
    [showInfo]="false"
    [showNavigationButtons]="true"
  >
  </dxo-pager>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
    
      <!-- <dxi-column
        dataField="id" [visible]="false"
        [width]="90"
        [hidingPriority]="2">
      </dxi-column> -->
      <dxi-column
      dataField="nameAr"
      [width]="190"
      caption="{{'tableinfo.NameAr'|translate}}"
      [hidingPriority]="8">

      <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}">
      </dxi-validation-rule>
      <dxi-validation-rule
      type="stringLength"
      [max]="50"
      message="{{'errors.NameLength' |translate}}"
    ></dxi-validation-rule>


      <dxi-validation-rule
            type="async"
            message="{{'errors.token'|translate}} !!" 
            [validationCallback]="validateAsync"

          >
          </dxi-validation-rule>
    </dxi-column>
      <dxi-column dataField="nameEn" caption="{{'tableinfo.NameEn'|translate}}" [hidingPriority]="6">
      <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}"></dxi-validation-rule>
    </dxi-column>

      <dxi-column type="buttons">
        <dxi-button name="edit" text="{{'tableinfo.editbutton'|translate}}"></dxi-button>
        <dxi-button name="delete" text="{{'tableinfo.removebutton'|translate}}"></dxi-button>
        
    </dxi-column>      
    <dxo-remote-operations
      [groupPaging]="true">
    </dxo-remote-operations>
    </dx-data-grid>
  </div>


 
  