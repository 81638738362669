import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PersonalSetting } from 'src/app/Interfaces/hr/personal-setting';
import { AfairsSettingService } from 'src/app/shared/services/hr/afairs-setting.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NotifyService } from 'src/app/shared/services/hr/notify.service';

@Component({
  selector: 'app-add-edit-personal-setting',
  templateUrl: './add-edit-personal-setting.component.html',
  styleUrls: ['./add-edit-personal-setting.component.scss']
})
export class AddEditPersonalSettingComponent implements OnInit {
  personalsettingform:any=[];
  btn: string="";
  subscription: Subscription;
  Afairsid:number

   todayvalue=[
    { text: this.translate.instant('personal.basic') , value: 1, },
    { text: this.translate.instant('personal.total') , value: 2, },
   ]
   valueofday=[
    { id: 1, text: this.translate.instant('personal.according') },
    { id: 2, text: this.translate.instant('personal.30days') },
    { id: 3, text: this.translate.instant('personal.days') },
    { id: 4, text: this.translate.instant('personal.specific') },
   ]
   dataview=[
    { id: 1, text: this.translate.instant('personal.fixesmonth') },
    { id: 2, text: this.translate.instant('personal.specific') },
   ]
   Forgettingfingerprint=[
    { id: 1, text: this.translate.instant('personal.dayabsence') },
    { id: 2, text: this.translate.instant('personal.disinterest') },
    { id: 3, text: this.translate.instant('personal.decision') },
   ]
   yesNo=[
    { id: true, text: this.translate.instant('personal.yes') },
    { id: false, text: this.translate.instant('personal.no') },
   ]

   buttonOptions = {
    text: this.translate.instant('tableinfo.savebutton'),
    type:"default",
    useSubmitBehavior: true,
  };
 
  // loading
  loading:boolean=false
  constructor(public translate:TranslateService, public AfairsSettingService:AfairsSettingService,public router:Router,public NotifyService:NotifyService,
    private cdref: ChangeDetectorRef) { 
      this.getdata()
  }
  ngOnInit(): void { }
   getdata(){
    this.loading=true
    this.subscription =this.AfairsSettingService.$PERSONAL_ID.subscribe(res=>{    
      this.Afairsid = res  
      if(this.Afairsid!=0){
         this.AfairsSettingService.getpersonalsetting(this.Afairsid).subscribe((res:PersonalSetting)=>{
          this.personalsettingform=res
          this.loading=false
          this.cdref.detectChanges()
         })
      }
      else{
        this.personalsettingform={}
        this.loading=false
      }
    })
  }

  onFormSubmit(e:any): void{
  e.preventDefault(); 
  if(this.Afairsid!=0){ 
    this.AfairsSettingService.putpersonalsetting(this.personalsettingform).subscribe(res=>{
      this.NotifyService.notifyfun('edit')
      this.router.navigate(['hr/afairsesetting/Personal'])

    })
}
  else{
    this.AfairsSettingService.addpersonalsetting(this.personalsettingform).subscribe(res=>{
      this.NotifyService.notifyfun('add')
      this.router.navigate(['hr/afairsesetting/Personal'])
    })
  }
}

}