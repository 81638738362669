
<div >
    <!-- Add DevExpress Button -->
     <div class="center-button">    <dx-button
        text="{{'cdc.sendmail' |translate}}"
        type="success"
        (onClick)="sendtomail()"
      ></dx-button> </div>
 
  
    <!-- Your Report Viewer Component -->
    <app-report-viewer [reportUrl]="reportUrl"></app-report-viewer>
  </div>


     

   

