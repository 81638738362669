import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SuperComponent } from 'src/app/pages/super/super.component';
import { genericCustomStoreService } from 'src/app/shared/services/genericCustomStore.service';
import { NotifyService } from 'src/app/shared/services/hr/notify.service';
import { ObjectsService } from 'src/app/shared/services/hr/objects.service';
import { publicSettingService } from 'src/app/shared/services/hr/publicSetting';

@Component({
  selector: 'app-items-search',
  templateUrl: './items-search.component.html',
  styleUrls: ['./items-search.component.scss']
})
export class ItemsSearchComponent extends SuperComponent implements OnInit {
  ItemSForm:any ={};
  seachedItems:any =[];
  SearchedItemsData: any= [];
  itemList: any= [];
  mainColorList: any= [];
  manufactureCompayList: any= [];
  MainDataSizingsList: any= [];
  MainDataScreeningsList: any= [];

  itemgroups : any =[] ;
  ItemGroupId:any;
  ItemId:any; 
  MainDataColorsId:any; 
  MainDataSizingId:any; 
  MainDataScreeningId:any; 
  ManufacturerCompanyId:any; 

  itemDropdown : any;
  buttonOptions = {
    text: this.translate.instant('tableinfo.search'),
    type: 'default',
    useSubmitBehavior: true,
  };


  constructor(Objects: ObjectsService, translate: TranslateService, gt: genericCustomStoreService, public NotifyService: NotifyService,
    public _publicSettingService: publicSettingService, ) {
    super(Objects, translate, gt)

    this.onValueItemsChanged = this.onValueItemsChanged.bind(this)

  }

  ngOnInit(): void {
    this.getItemslistGroup()
    this.getManufactureCompanyList()
    this.getMainColorDataList();
    this.getMainDataSizingsList()
    this.getMainDataScreeningsList()
  this.gt.getdropdowndata("Items").subscribe((res: []) => this.itemDropdown = res)
  }

  getItemslistGroup () {
    this.gt.getdropdowndata("ItemGroup","GetDropDown").subscribe((res:any)=>{
      this.itemgroups=res;
      // console.log(" this.itemList", this.itemList)

    })
  }

  getManufactureCompanyList() {
    this.gt.getdropdowndata('ManufacturerCompany').subscribe((res:any)=>{
      this.manufactureCompayList = res;
      // console.log(" this.manufactureCompayList", this.manufactureCompayList)
    })
  }

  getMainColorDataList () {
    this.gt.getdropdown('MainDataColors').subscribe((res:any)=>{
      this.mainColorList = res;
      // console.log(" this.mainColorList", this.mainColorList)

    })
  }
  getMainDataSizingsList () {
    this.gt.getdropdown('MainDataSizings').subscribe((res:any)=>{
      this.MainDataSizingsList = res;
      // console.log("this.MainDataSizingsList",this.MainDataSizingsList)

    })
  }
  getMainDataScreeningsList () {
    this.gt.getdropdown('MainDataScreenings').subscribe((res:any)=>{
      this.MainDataScreeningsList = res;
      // console.log("this.MainDataScreeningsList",this.MainDataScreeningsList)
// 
    })
  }

  onValueItemsChanged (e:any) {
    console.log(this)
    debugger
    this.ItemId = e.value
  }

  onValueMainColorListChanged (e:any) {
    this.MainDataColorsId = e.value
  }

  onValueMainSizeChanged (e:any) {
    this.MainDataSizingId = e.value
  }

  onValueDataScreeningChanged (e:any) {
    this.MainDataScreeningId = e.value
  }

  onValueMaufacturChanged (e:any) {
    this.ManufacturerCompanyId = e.value
  }


  onFormSubmit(event:any) {
    event.preventDefault();
    debugger
    console.log(this.ItemSForm)
    this.gt.searchItemsGroup( this.ItemSForm.ItemGroupId, this.ItemSForm.ItemId, this.ItemSForm.MainDataColorsId, this.ItemSForm.MainDataScreeningId, this.ItemSForm.MainDataSizingId, this.ItemSForm.ManufacturerCompanyId).subscribe((res:any)=>{
      this.SearchedItemsData = res;
      console.log("this.seachedItems ", this.seachedItems)
    })
  }
}
