<div class="faspinner" *ngIf="loading">
    <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
</div>

<div id="form-container" *ngIf="!loading">
    <form action="" (submit)="onFormSubmit($event)">
        <dx-form id="form" [(formData)]="form" labelMode="outside" labelLocation="top"
            [showColonAfterLabel]="true" style="padding: 1rem 2rem;margin: 2rem 0rem;">
            <dxi-item itemType="group" [colCount]="12">
                <dxi-item dataField="nameAr" [colSpan]="6">
                    <dxo-label template="{{'tableinfo.NameAr'|translate}}"></dxo-label>
                    <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}">
                    </dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="nameEn" [colSpan]="6">
                    <dxo-label template="{{'tableinfo.NameEn'|translate}}"></dxo-label>
                    <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}">
                    </dxi-validation-rule>
                  </dxi-item>
                <dxi-item dataField="taxExemptionDiscountValue" [colSpan]="6" editorType="dxNumberBox">
                  <dxo-label template="{{'insurance.taxamount'|translate}}"></dxo-label>
                  <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}">
                  </dxi-validation-rule>
                </dxi-item>
                </dxi-item>
            <dxi-item itemType="group" [colCount]="12">
                <dx-data-grid id="gridContainer" [dataSource]="dataSource" keyExpr="guid" [allowColumnReordering]="true"
                    [showBorders]="false" (onInitNewRow)="onInitNewRow($event)" [columnAutoWidth]="true"
                    [columnHidingEnabled]="true" [showColumnLines]="false" [rowAlternationEnabled]="true"
                    [showRowLines]="false" [showBorders]="false" [errorRowEnabled]="false"
                    [rtlEnabled]="currentLang=='ar'?true:false">
                    <dxo-paging [enabled]="true"></dxo-paging>
                    <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">

                        <dxo-texts [addRow]="addrowfun('personnelaffairs.tax_branch')"
                            confirmDeleteMessage="{{'tableinfo.deletebutton'|translate}}" SaveConfirm>
                        </dxo-texts>
                    </dxo-editing>
                    <dxi-column dataField="id" dataType="number" [visible]="false">
                        <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
                        </dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="uniqNumber" caption="{{'insurance.data.TaxSettingsNumber'|translate}}">
                        <dxi-validation-rule type="required"
                            message="{{'errors.required'|translate}}"></dxi-validation-rule>
                        <dxi-validation-rule type="custom" message="{{'errors.token'|translate}}"
                            [validationCallback]="validateCustom">
                        </dxi-validation-rule>
                    </dxi-column>

                    <dxi-column dataField="fromValue" caption="{{'insurance.data.From'|translate}}" dataType="number"
                        [setCellValue]="setCellValuefromfield"></dxi-column>
                    <dxi-column dataField="toValue" caption="{{'insurance.data.to'|translate}}" dataType="number"
                        [setCellValue]="setCellValuetofield">
                        <dxi-validation-rule type="range" [min]="mintovalue"
                            message="{{'errors.attendance'|translate}}"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="taxValue" [allowEditing]="false"
                        caption="{{'insurance.data.TaxValue'|translate}}" dataType="number"></dxi-column>
                    <dxi-column dataField="taxRate" caption="{{'insurance.data.taxrate'|translate}}" dataType="number"
                        [setCellValue]="setCellValuetaxRatefield"></dxi-column>
                    <dxi-column dataField="discountPercentageValue" [allowEditing]="false"
                        caption="{{'insurance.data.discountpercentage'|translate}}" dataType="number"></dxi-column>
                </dx-data-grid>
            </dxi-item>
            <dxi-item [editorOptions]="{disabled:savebutton=='show'}"
            itemType="button"
            [buttonOptions]="buttonOptions"
           >
            </dxi-item>
        </dx-form>
    </form>
</div>