<div class="faspinner" *ngIf="loading">
  <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
</div>
<div *ngIf="!loading" class="publicsetting" style="padding: 3rem 3rem">
  <dx-data-grid class="dx-card wide-card" 
  [dataSource]="customDataSource" 
  [showBorders]="false"
  [focusedRowEnabled]="true" 
  [focusedRowIndex]="0"
  [allowColumnResizing]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [showColumnLines]="false"
  [rowAlternationEnabled]="true"
  [showRowLines]="false"
  [showBorders]="false"
  [errorRowEnabled]="false"
  [rtlEnabled]="currentLang=='ar'?true:false" (onInitNewRow)="onEditingStart($event)"
    (onEditingStart)="onEditingStart($event)" (onRowRemoving)="onRowRemoving($event)" >
    <dxo-editing mode="popup" [useIcons]="false" [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true"
      [texts]="button">
      <dxo-popup [title]="addpopup" [showTitle]="true"  [hideOnOutsideClick]="true">
      </dxo-popup>
      <dxo-texts [addRow]="addrowfun('personnelaffairs.personalsetting')"
        confirmDeleteMessage="{{'tableinfo.deletebutton'|translate}}">
      </dxo-texts>
    </dxo-editing>
      <dxo-paging [pageSize]="10"> </dxo-paging>
  <dxo-pager
    [visible]="true"
    allowedPageSizes="true"
    displayMode="full"
    [showPageSizeSelector]="true"
    [showInfo]="false"
    [showNavigationButtons]="true"
  >
  </dxo-pager>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxi-column dataField="nameAr" caption="{{'tableinfo.NameAr'|translate}}">
    </dxi-column>
    <dxi-column dataField="nameEn" caption="{{'tableinfo.NameEn'|translate}}">
    </dxi-column>
    <dxi-column type="buttons">
      <dxi-button name="edit" text="{{'tableinfo.editbutton'|translate}}"></dxi-button>
      <dxi-button name="delete" text="{{'tableinfo.removebutton'|translate}}"></dxi-button>
    </dxi-column>
    <dxo-remote-operations [groupPaging]="true">
    </dxo-remote-operations>
  </dx-data-grid>
</div>