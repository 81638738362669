import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { genericCustomStoreService } from 'src/app/shared/services/genericCustomStore.service';
import { loadMessages } from 'devextreme/localization';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-afairs-tab',
  templateUrl: './afairs-tab.component.html',
  styleUrls: ['./afairs-tab.component.scss']
})
export class AfairsTabComponent implements OnInit {
  employeeAfairsTab: any = [];
  id: number;
  LOCALIZATION_LOCAL_STORAGE_KEY = "Language";
  currentLang: any;
  selectedIndex: number
  lastItem: string
  activetabs: any = []
  constructor(public router: Router, public genericCustomStoreService: genericCustomStoreService, public translate: TranslateService) {
    this.currentLang = localStorage.getItem(
      this.LOCALIZATION_LOCAL_STORAGE_KEY
    );

    loadMessages({
      en: { "Yes": this.translate.instant('personal.yes'), "No": this.translate.instant('personal.no') }
    });
    this.genericCustomStoreService.sessiondata.subscribe(data => this.employeeAfairsTab = data)
    this.lastItem = this.router.url.substring(this.router.url.lastIndexOf('/') + 1)
    //get tab data
    this.employeeAfairsTab.forEach((tab: []) => {
      tab.filter((ele: { externalUrl: string }) => {
        ele.externalUrl.includes(this.lastItem) ? this.activetabs = tab : ''
      })
    })

    this.changekey()
  }

  changekey() {
    //change key because dx-tabs use text key to read datascource
    for (var i = 0; i < this.activetabs.length; i++) {
      var o = this.activetabs[i];
      o.text = this.currentLang == 'ar' ? o.nameAr : o.nameEn
    }
  }

  ngOnInit(): void {
    let activetab = this.activetabs.filter((tab: any) => {
      return tab.externalUrl.includes(this.lastItem) ? tab : ''
    })
    let index = this.activetabs.indexOf(activetab[0])
    this.selectedIndex = index
  }
  selectTab(e: any) {
    this.router.navigate([e.itemData.externalUrl])
    this.changekey()
  }



}
