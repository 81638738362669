import {  Component, OnInit ,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { SuperComponent } from 'src/app/pages/super/super.component';
import { genericCustomStoreService } from 'src/app/shared/services/genericCustomStore.service';
import { AfairsSettingService } from 'src/app/shared/services/hr/afairs-setting.service';
import { NotifyService } from 'src/app/shared/services/hr/notify.service';
import { ObjectsService } from 'src/app/shared/services/hr/objects.service';

@Component({
  selector: 'app-personal-setting',
  templateUrl: './personal-setting.component.html',
  styleUrls: ['./personal-setting.component.scss']
})
export class PersonalSettingComponent extends SuperComponent implements OnInit {
  customDataSource: any;
  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  constructor( translate: TranslateService,public router:Router,Objects: ObjectsService,public AfairsSettingService:AfairsSettingService,
      gt: genericCustomStoreService,public route:ActivatedRoute,public NotifyService:NotifyService) {
      super(Objects, translate, gt)
      this.loading=true
     this.gt.getdropdowndata('EmployeeAfairsSetting').subscribe(res=>{
      this.customDataSource =res;
      this.loading=false
     })
    setTimeout(() => {
      this.grid.instance.refresh()
    }, 500)
  }
  ngOnInit(): void { }
  onEditingStart(e:any) {    
    e.cancel=true
    this.router.navigate(['../add-edit-EmployeeAfairs-Setting'],{relativeTo:this.route})
    this.AfairsSettingService.$PERSONAL_ID.next(e.key?.id ?? 0)
  }
  onRowRemoving(e: any) {
    this.AfairsSettingService.deleteRow('EmployeeAfairsSetting', e.data.id).subscribe(res=>{
      this.NotifyService.notifyfun('delete')
    },(err=>{
      this.gt.getdropdowndata('EmployeeAfairsSetting').subscribe(res=>{
        this.customDataSource =res;
       })  
    }))

  }
}