import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { NotifyService } from './notify.service';
import {BehaviorSubject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AfairsSettingService {
  private handleError: HandleError;
  constructor(public http:HttpClient,public httpErrorHandler: HttpErrorHandler,public NotifyService:NotifyService) {
    this.handleError = httpErrorHandler.createHandleError('ObjectService');
   }
 //behaviour subject to change data when select from dropdown
 $INSURANCE_ID = new BehaviorSubject<number>(0);
 $PERSONAL_ID = new BehaviorSubject<number>(0);
 $TAX_ID = new BehaviorSubject<number>(0);
  //tax setting
  gettaxsetting(id:number){
    return this.http.get(`${environment.apiRoot}/api/TaxScheduleSetting/GetById/${id}`)
  }
  puttaxsetting(data:any={}){
    return this.http.put(`${environment.apiRoot}/api/TaxScheduleSetting/Update`,data)
  }
  addtaxesetting(data:any={}){
    return this.http.post(`${environment.apiRoot}/api/TaxScheduleSetting/Add`,data)
  }

  //insurance setting
  getinsurancesetting(id:number){
    return this.http.get(`${environment.apiRoot}/api/InsuranceSetting/GetInsurance?Id=${id}`)
  }
  putinsurancesetting(data:any={}){
    return this.http.put(`${environment.apiRoot}/api/InsuranceSetting/UpdateInsurance`,data)
  }
  addinsurancesetting(data:any={}){
    return this.http.post(`${environment.apiRoot}/api/InsuranceSetting/AddInsurance`,data)
  }

  //personal setting

  getpersonalsetting(id:number){
    return this.http.get(`${environment.apiRoot}/api/EmployeeAfairsSetting/GetEmployeeAfairs?Id=${id}`)
  }
  putpersonalsetting(data:any={}){
    return this.http.put(`${environment.apiRoot}/api/EmployeeAfairsSetting/UpdateEmployeeAfairs`,data)
  }
  addpersonalsetting(data:any={}){
    return this.http.post(`${environment.apiRoot}/api/EmployeeAfairsSetting/AddEmployeeAfairs`,data)
  }

  //delete
  deleteRow(path:string,id:number){
    return this.http.delete(`${environment.apiRoot}/api/${path}/Delete?Id=${id}`)
  }
  

}
