import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { catchError } from 'rxjs';
import { HandleError, HttpErrorHandler } from 'src/app/shared/services/http-error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavigationMenuService {
  private handleError: HandleError;
  constructor(public http:HttpClient,public httpErrorHandler: HttpErrorHandler) { 
    this.handleError = httpErrorHandler.createHandleError('ObjectService');
    const isNotEmpty = (value: any) => value !== undefined && value !== null && value !== '';
  }

  postnavigationmenu(path:string,data:{}){
    return this.http.post(`${environment.apiRoot}/api/${path}/Add`,data).pipe(catchError(
      this.httpErrorHandler.handleError('get'))).toPromise().then((res:any)=>{
        if (res.succeeded) {
          notify({
            message: `Toast ${res.message}`,
            height: 45,
            width: 900,
            minWidth: 150,
            type: 'success',
            displayTime: 1500,
            animation: {
              show: {
                type: 'fade', duration: 100, from: 0, to: 1,
              },
              hide: { type: 'fade', duration: 40, to: 0 },
            },
          })
        }
       
      })
  }
  editnavigationmenu(path:string,data:{}){
    return this.http.put(`${environment.apiRoot}/api/${path}/Update`,data).pipe(catchError(
      this.httpErrorHandler.handleError('Update'))).toPromise().then((res:any)=>{
        if (res.succeeded) {
          notify({
            message: `Toast ${res.message}`,
            height: 45,
            width: 900,
            minWidth: 150,
            type: 'success',
            displayTime: 1500,
            animation: {
              show: {
                type: 'fade', duration: 100, from: 0, to: 1,
              },
              hide: { type: 'fade', duration: 40, to: 0 },
            },
          })
        }
       
      })
  }
  }

