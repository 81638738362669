import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/data/odata/store';
import {lastValueFrom ,BehaviorSubject, elementAt } from 'rxjs';
import { MessageResponse } from 'src/app/Interfaces/MessageResponse';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from './hr/notify.service';
import { DatePipe } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class genericCustomStoreService {
  //permission
   session:any = sessionStorage.getItem("data");
   data = JSON.parse(this.session);
   navihationMenu = this.data?.navihationMenu
  //
  private url: string;
  public sessiondata= new BehaviorSubject<[]>([])
  public $NavigationMenu=new BehaviorSubject<number>(0)
  //comuncation different module
  $PaymentDropDownsValues=new BehaviorSubject<{}[]>([])
  $HOMETAB=new BehaviorSubject<boolean>(false)

  
  $Ids = new BehaviorSubject<number[]>([]);
  //home
  $home=new BehaviorSubject<{}[]>([])
  public seturl(value: string) {
    this.url = value;
  }
  dataSource: any;

  //routing permission
  $ROUTINGPERMISSION=new BehaviorSubject<{}[]>([])

  // private handleError: HandleError;
  public customDataSource: any;
  constructor(private http: HttpClient, public DatePipe: DatePipe, public translatee: TranslateService,public NotifyService:NotifyService) {
    // this.handleError = httpErrorHandler.createHandleError('ObjectService');    
  }

  public submit() {
    this.customDataSource = new CustomStore({
      key: 'id',
      loadMode: 'raw',

      load: () => {
        return lastValueFrom(this.http.get(`${environment.apiRoot}/api/${this.url}/all`))
          .catch(() => { throw 'Data loading error' });
      },
      insert: (values) => {        
        return this.http.post<any>(`${environment.apiRoot}/api/${this.url}/Add`, values).pipe(  
        ).toPromise().then((message:MessageResponse)=>{
           this.NotifyService.notifyfun('add')
        })
      },

      update: (key, values) => {
        return this.http.put(`${environment.apiRoot}/api/${this.url}/Update`, values).toPromise().then(res=>{
          this.NotifyService.notifyfun('edit')
          return res
        },(err=>{
          return err          
        }))
      },
      remove: (key) => {
        return this.http.delete(`${environment.apiRoot}/api/${this.url}/Delete?id=` + encodeURIComponent(key)).toPromise().then((message: MessageResponse) => {
          this.NotifyService.notifyfun('delete')

        })
      }
    });
  }
  getdropdown(url: string) {
    return this.http.get(`${environment.apiRoot}/api/${url}/all`) 
  }
  getdropdowndata(url: string,endpoint:string='GetDropDown',state: string = 'false') {
    return this.http.get(`${environment.apiRoot}/api/${url}/${endpoint}`,{headers:{state}})
  }

  dropdown(url: string) {
    return this.http.get(`${environment.apiRoot}/api/${url}`)
  }

  updateRow(options: any) {
    options.newData = Object.assign(options.oldData, options.newData);
  }
   getdatabyid(id: number,path:string) {
   return this.http.get(`${environment.apiRoot}/api/${path}/GetById/${id}`)
  }


  changepassword(data:{}){
    return this.http.put(environment.apiRoot + `/api/User/ResetPasswordUser`,data)
  }

  

  onEditorPreparing(e:any) {
    if (e.parentType == 'dataRow') {  
      e.editorOptions.onInitialized = function(arg:any) {  
      arg.component.registerKeyHandler("escape", function(arg:any) {  
          e.component.cancelEditData();  
      });  
      }  
  }
}

addrow(name:string){
  let actiontranslate= this.translatee.instant('tableinfo.addbutton');
  let nametranslate= this.translatee.instant(name);
  return `${actiontranslate} ${nametranslate}`
}

translatefunction(name:string){
  return this.translatee.instant(name); 
}
getgovernorates(value: number) {
  return this.http.get(`${environment.apiRoot}/api/Fillters/FillterAdress?Id=${value}`)
}

sendreportmail(reporturl : any) {
  return this.http.get(`${environment.apiRoot}/api/ReportsType/SendReportMail?ReportUrl=${reporturl}`)
}


covertDateToTime(value:any){
  return this.DatePipe.transform(value, 'HH:mm:ss')
}

returnPermission(controller:string,action:string){
  let itemFound=this.returnItem(controller)
  let actions=itemFound?.permissionActions.find((actions:{name:string})=>{
    return actions.name==action
  })    
  return actions?.visable
}

returnItem(controller:string){
  return this.navihationMenu.find((res:{controllerName:string})=>{
    return res.controllerName==controller
  })
}

uploaddPhoto(data: FormData) {
  return this.http.post(environment.apiRoot + `/api/CdcExpense/UploadAttachment`, data);
}



//payment on edit
    returneditpayment(paymentMethods:any,paymentDropDown:any){
     paymentMethods.forEach((element: { paymentId: number, paymentType: number,guid:string,currency:number }) => {
     this.returnpayment(paymentDropDown,element).then((res:any)=>{      
      element.guid = res?.guid
      element.currency = res?.currencyId      
    })    
  });  
    return paymentMethods
   }

returnpayment(paymentDropDown:any,element:{paymentId:number,paymentType:number}){
  return new Promise(resolve=>{
    let data: any = paymentDropDown.find((res: {id: number, paymentType: number }) => {
      return res.id == element.paymentId && res.paymentType == element.paymentType
    })
    resolve(data)
  })
}


searchItemsGroup(ItemGroupId?:any, ItemId?:any , MainDataColorsId?:any, MainDataSizingId?:any , MainDataScreeningId?:any ,ManufacturerCompanyId?:any) {
let params = new HttpParams()
if(ItemGroupId) params =params.append('ItemGroupId' ,ItemGroupId)
if(ItemId) params =params.append('ItemId' ,ItemId)
if(MainDataColorsId) params =params.append('MainDataColorsId' ,MainDataColorsId)
if(MainDataSizingId) params =params.append('MainDataSizingId' ,MainDataSizingId)
if(MainDataScreeningId) params =params.append('MainDataScreeningId' ,MainDataScreeningId)
if(ManufacturerCompanyId) params =params.append('ManufacturerCompanyId' ,ManufacturerCompanyId)
  return this.http.get(`${environment.apiRoot}/api/Items/Search`, {params})
}


Delete(id: any ,url  :string) {
  return this.http.delete(`${environment.apiRoot}/api/${url}/Delete?Id=${id}`)

  
}
}
