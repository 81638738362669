<div class="faspinner" *ngIf="loading">
    <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
  </div>
  <div id="form-container" *ngIf="!loading">
    <form action="" (submit)="onFormSubmit($event)">
      <dx-form id="form" 
      [(formData)]="personalsettingform"
      labelMode="outside" 
      labelLocation="top"
      [showColonAfterLabel]="true"
      style="padding: 1rem 2rem;margin: 2rem 0rem;">
      <dxi-item dataField="nameAr" [colSpan]="6">
        <dxo-label template="{{'tableinfo.NameAr'|translate}}"></dxo-label>
        <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}">
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="nameEn" [colSpan]="6">
        <dxo-label template="{{'tableinfo.NameEn'|translate}}"></dxo-label>
        <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}">
        </dxi-validation-rule>
      </dxi-item>
  
          <dxi-item dataField="monthEndDay" editorType="dxNumberBox">
            <dxo-label template="{{'personal.endday'|translate}}"></dxo-label>
            <dxi-validation-rule type="range" message="{{'errors.range'|translate}}" [min]="0" [max]="30">
            </dxi-validation-rule>
          </dxi-item>
  
        <dxi-item dataField="countDayType" editorType="dxRadioGroup" 
        [editorOptions]="{items: todayvalue, layout: 'horizontal', displayExpr:'text' ,valueExpr :'value'}" >
          <dxo-label template="{{'personal.todayvalue'|translate}}"></dxo-label>
        </dxi-item>
       
          <dxi-item dataField="countDayFrom" editorType="dxRadioGroup" 
          [editorOptions]="{items: valueofday, layout: 'horizontal', displayExpr:'text' ,valueExpr :'id'}"
          >
            <dxo-label template="{{'personal.valueday'|translate}}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="countDayFromNum" *ngIf="personalsettingform?.countDayFrom==4"
           editorType="dxNumberBox" >
          <dxo-label template="{{'personal.valueday'|translate}}"></dxo-label>
          <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
          </dxi-validation-rule>
          </dxi-item>
       
  
        <dxi-item dataField="infoDisplay"
        editorType="dxRadioGroup" 
        [editorOptions]="{items: dataview, layout: 'horizontal', displayExpr:'text' ,valueExpr :'id'}"
        >
          <dxo-label template="{{'personal.dataview'|translate}}"></dxo-label>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2" caption="{{'personal.early'|translate}}" >
          <dxi-item dataField="totalLeaveEarlyMonth" editorType="dxNumberBox">
            <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
            </dxi-validation-rule>
              <dxo-label template="{{'personal.totalnumber'|translate}}"></dxo-label>
            </dxi-item>
      
            <dxi-item dataField="leaveEarlyDaily" editorType="dxNumberBox">
              <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
              </dxi-validation-rule>
              <dxo-label template="{{'personal.maximum'|translate}}"></dxo-label>
            </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2" caption="{{'personal.totalarrive'|translate}}">
            <dxi-item dataField="totalAttendDelayMonth" editorType="dxNumberBox">
              <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
              </dxi-validation-rule>  
              <dxo-label template="{{'personal.totalnumber'|translate}}"></dxo-label>
            </dxi-item>
      
            <dxi-item dataField="attendDelayDaily" editorType="dxNumberBox">
              <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
              </dxi-validation-rule>
              <dxo-label template="{{'personal.maximum'|translate}}"></dxo-label>
            </dxi-item>
      
        </dxi-item>
        <dxi-item dataField="maxSequentiallyVacation" editorType="dxNumberBox">
          <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
          </dxi-validation-rule>
          <dxo-label template="{{'personal.maxvacation'|translate}}"></dxo-label>
        </dxi-item>
  
        <dxi-item dataField="forgetFingerPrintAttend"
        editorType="dxRadioGroup" 
        [editorOptions]="{items: Forgettingfingerprint, layout: 'horizontal', displayExpr:'text' ,valueExpr :'id'}">
          <dxo-label template="{{'personal.forgetattend'|translate}}"></dxo-label>
        </dxi-item>
  
        <dxi-item dataField="forgetFingerPrintLeave"
        editorType="dxRadioGroup" 
        [editorOptions]="{items: Forgettingfingerprint, layout: 'horizontal', displayExpr:'text' ,valueExpr :'id'}"
        >
          <dxo-label template="{{'personal.forgetexit'|translate}}"></dxo-label>
        </dxi-item>

        <dxi-item dataField="maximumAdvanceValue" editorType="dxNumberBox">
          <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
          </dxi-validation-rule>
          <dxo-label template="{{'personal.MaximumAdvanceValue'|translate}}"></dxo-label>
        </dxi-item>
  
        <dxi-item dataField="maxAdvance" editorType="dxNumberBox">
          <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
          </dxi-validation-rule>
          <dxo-label template="{{'personal.advance'|translate}}"></dxo-label>
        </dxi-item>
        
        <dxi-item dataField="numberOfMonthsOfAdvances" editorType="dxNumberBox">
          <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
          </dxi-validation-rule>
          <dxo-label template="{{'personal.numberOfMonthsOfAdvances'|translate}}"></dxo-label>
        </dxi-item>
  
      
  
        <dxi-item dataField="isDecimalRounding"
        editorType="dxRadioGroup" 
        [editorOptions]="{items: yesNo, layout: 'horizontal', displayExpr:'text' ,valueExpr :'id'}"
        >
          <dxo-label template="{{'personal.decimal'|translate}}"></dxo-label>
        </dxi-item>
  
        <dxi-item dataField="isActive" editorType="dxCheckBox">
          <dxo-label template="{{'tableinfo.isActive'|translate}}"></dxo-label>
        </dxi-item>
          <dxi-item  [editorOptions]="{disabled:btn=='show'}"
        itemType="button"
        [buttonOptions]="buttonOptions"
       >
        </dxi-item>
      </dx-form>
    </form>
    </div>
    
  