import { HttpClient } from '@angular/common/http';
import {Component, OnInit } from '@angular/core';
import { TaxBracket, TaxSetting } from 'src/app/Interfaces/hr/tax-setting';
import { genericCustomStoreService } from 'src/app/shared/services/genericCustomStore.service';
import { AfairsSettingService } from 'src/app/shared/services/hr/afairs-setting.service';
import { ObjectsService } from 'src/app/shared/services/hr/objects.service';
import { loadMessages } from 'devextreme/localization';
import { TranslateService } from '@ngx-translate/core';
import * as uuid from 'uuid';
import { NotifyService } from 'src/app/shared/services/hr/notify.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-edit-tax-setting',
  templateUrl: './add-edit-tax-setting.component.html',
  styleUrls: ['./add-edit-tax-setting.component.scss']
})
export class AddEditTaxSettingComponent implements OnInit {
  form:any={}
  dataSource: TaxBracket[] = []
  mintovalue: number = 0;
  taxData: any = {};
  savebutton: string;
  LOCALIZATION_LOCAL_STORAGE_KEY = "Language";
  currentLang: any;
  activeId:number
  //loading
  loading: boolean = false
  buttonOptions = {
    text: this.translate.instant('tableinfo.savebutton'),
    type: "default",
    useSubmitBehavior: true,
  };
  constructor(public gt: genericCustomStoreService, public translate: TranslateService,public NotifyService:NotifyService,
    public Objects: ObjectsService, public http: HttpClient, public _AfairsSettingService: AfairsSettingService,public router:Router) {
    //debugger
    this.setCellValuefromfield = this.setCellValuefromfield.bind(this);
    this.setCellValuetofield = this.setCellValuetofield.bind(this);
    this.validateCustom = this.validateCustom.bind(this)
    this.currentLang = localStorage.getItem(
      this.LOCALIZATION_LOCAL_STORAGE_KEY
    );

    loadMessages({
      en: { "Yes": this.translate.instant('personal.yes'), "No": this.translate.instant('personal.no') }
    });
    this.getdata()
  }
  ngOnInit(): void {}
   getdata() {
    this._AfairsSettingService.$TAX_ID.subscribe( res => {
      this.activeId=res
      this.loading=true      
      if(this.activeId!=0){
        this._AfairsSettingService.gettaxsetting(this.activeId).subscribe((res: TaxSetting) => {
            this.form = res;
            res?.taxBrackets.forEach((res: any) => {
              res.guid = uuid.v4()
            })
            this.dataSource = res.taxBrackets
            this.loading = false
          })
      }
      else{
        this.form=[]
        this.dataSource=[]
        this.loading=false
      }
      
    })
  }

  updateRow(options: any) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  setCellValuefromfield(newData: any, value: any,current: any) {    
    newData.fromValue = value;
    this.mintovalue=value
    newData.taxValue = current?.toValue  - value
  }
  setCellValuetofield(newData: any, value: any, current: any) {
    newData.toValue = value;
    newData.taxValue = value - current.fromValue
  }
  setCellValuetaxRatefield(newData: any, value: any, current: any) {
    newData.taxRate = value;
    newData.discountPercentageValue = (value * current?.taxValue) / 100
  }

  validateCustom(e: any) {
    let result = this.dataSource.filter((ele: any) => { return ele.uniqNumber == e.value })
    if (result.length != 0 && this.dataSource.indexOf(result[0]) != this.dataSource.findIndex((x: any) => x.guid === e.data.guid)) {
      return false
    }
    else{
      return true
    }
  }

  onInitNewRow(e: any) {
    e.data.id = 0;
    e.data.guid = uuid.v4();
  }

  addrowfun(name: string) {
    return this.gt.addrow(name)
  }
  onFormSubmit(e: any): void {
    e.preventDefault();
    let data={
      ...this.form,
      taxBrackets:this.dataSource
    }
    if (this.activeId!=0) {
      this._AfairsSettingService.puttaxsetting(data).subscribe(res=>{
        this.router.navigate(['hr/afairsesetting/Tax'])
        this.NotifyService.notifyfun('edit')
      })
    }
    else {
      this._AfairsSettingService.addtaxesetting(data).subscribe(res=>{
        this.router.navigate(['hr/afairsesetting/Tax'])
        this.NotifyService.notifyfun('edit')
      })

    } 
  }
}