import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { genericCustomStoreService } from 'src/app/shared/services/genericCustomStore.service';
import { CheckValidation } from 'src/app/shared/services/hr/CheckValidation.service';
import { ObjectsService } from 'src/app/shared/services/hr/objects.service';
import { HandleError, HttpErrorHandler } from 'src/app/shared/services/http-error-handler.service';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {

  button={};
  private handleError: HandleError;
  customDataSource: any;
  date=new Date()
  LOCALIZATION_LOCAL_STORAGE_KEY = "Language";
  currentLang:any;
  
  constructor(httpErrorHandler: HttpErrorHandler, public gt:genericCustomStoreService
  ,private validation:CheckValidation,public Objects:ObjectsService,public router:Router) {
    //debugger
    gt.seturl("NavigationMenu");
    gt.submit();
    this.handleError = httpErrorHandler.createHandleError('NavigationMenuService');
    
    this.customDataSource = gt.customDataSource;
    this.button=Objects.button;

    this.validateAsync  = this.validateAsync.bind(this);
    this.currentLang = localStorage.getItem(
      this.LOCALIZATION_LOCAL_STORAGE_KEY
    );
  }
  ngOnInit(): void {}
  validateAsync (params:any) {
    var id = params.data.id??0;
    return this.validation.asyncCheckNameValue(params.value,id,"NavigationMenu");
  }

  updateRow(e:any) {
    this.router.navigate(['EditNavigationMenu']) 
    this.gt.$NavigationMenu.next(e.key?e.key:0)
  }                                                                                     
  onEditorPreparing(e:any) {
    this.gt.onEditorPreparing(e)
    }
}
