import { Injectable } from '@angular/core';
import { loadMessages } from 'devextreme/localization';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  currentLang: any;
  localeEvent = new Subject<string>();
  LOCALIZATION_LOCAL_STORAGE_KEY = "Language";
  constructor(public translate: TranslateService) { }
  changeLang(lang: string) {
    this.currentLang = localStorage.getItem(this.LOCALIZATION_LOCAL_STORAGE_KEY);
    if (this.currentLang !== lang) {
      localStorage.setItem(this.LOCALIZATION_LOCAL_STORAGE_KEY, lang);
      window.location.reload();
    }
    setTimeout(() => {
      this.translate.use(lang);
      this.localeEvent.next(lang);

      let direction =
        localStorage.getItem(this.LOCALIZATION_LOCAL_STORAGE_KEY) === "ar"
          ? "rtl"
          : "ltr";
      document.documentElement.dir = direction;
      document.documentElement.lang = this.currentLang;

      let getMain = document.getElementsByTagName("html")[0];
      getMain.setAttribute("lang", this.currentLang);
      getMain.setAttribute("class", this.currentLang);
    }, 1000);
  }

  /**
   * Returns selected language
   */
  getSelectedLanguage(): any {
    return (
      localStorage.getItem("Language") || this.translate.getDefaultLang()
    );
  }
}
