 <form (submit)="onFormSubmit($event)" >
    <dx-form id="form"  
    [(formData)]="navigationmenuform"
    labelMode="outside" 
    labelLocation="top"
    [showColonAfterLabel]="true"
    [showValidationSummary]="true"
    validationGroup="customerData"
    style="padding: 1rem 2rem;margin: 1rem 0rem;">
    <dxi-item itemType="group" [colSpan]="12" [colCount]="12">
        <dxi-item dataField="nameAr" [colSpan]="6">
          <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}"></dxi-validation-rule>
          <dxo-label template="{{'tableinfo.NameAr'|translate}}"></dxo-label>
        </dxi-item>
        <dxi-item dataField="nameEn" [colSpan]="6">
            <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}"></dxi-validation-rule>
            <dxo-label template="{{'tableinfo.NameEn'|translate}}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="parentId" 
          editorType="dxLookup" 
          [editorOptions]="{dataSource: parentId, displayExpr:'nameAr' ,valueExpr :'id',searchEnabled:'true',
          searchMode:'contains',searchExpr: ['nameAr'],
          minSearchLength:'1',showDataBeforeSearch:'true'}"
          [colSpan]="6">
          <dxo-label template="Head"></dxo-label>
          </dxi-item>
          <dxi-item dataField="controllerName" [colSpan]="6">
            <dxo-label template="{{'navigationMenu.controllerName'|translate}}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="actionName" [colSpan]="6">
            <dxo-label template="{{'navigationMenu.actionName'|translate}}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="styleClass" [colSpan]="6">
            <dxo-label template="{{'navigationMenu.styleClass'|translate}}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="displayOrder" editorType="dxNumberBox" [colSpan]="6" >
            <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0"></dxi-validation-rule>
           <dxo-label template="{{'navigationMenu.displayOrder'|translate}}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="externalUrl" [colSpan]="6" >
           <dxo-label template="{{'navigationMenu.externalUrl'|translate}}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="visable" editorType="dxCheckBox" [colSpan]="12" >
            <dxo-label template="{{'navigationMenu.visable'|translate}}"></dxo-label>
          </dxi-item> 
        
  <dxi-item
      itemType="button"
      horizontalAlignment="left"
      [buttonOptions]="buttonOptions"
    >
    </dxi-item>
    </dxi-item>
  </dx-form>
</form>