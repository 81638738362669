import { Component, OnInit } from '@angular/core';
import { loadMessages } from 'devextreme/localization';
import { TranslateService } from '@ngx-translate/core';
import { genericCustomStoreService } from 'src/app/shared/services/genericCustomStore.service';
import { ObjectsService } from 'src/app/shared/services/hr/objects.service';
import { NavigationMenuService } from '../navigation-menu.service';
import {Router } from '@angular/router';

@Component({
  selector: 'app-edit-navigation-menu',
  templateUrl: './edit-navigation-menu.component.html',
  styleUrls: ['./edit-navigation-menu.component.scss']
})
export class EditNavigationMenuComponent implements OnInit {
  navigation_active_Id:number=0
  navigationmenuform:any={}
  parentId:{}[]=[]
  permissionAction:[]=[]
  buttonOptions = {
    text: this.translate.instant('employeeduration.submit'),
    type: 'default',
    useSubmitBehavior: true,
  };
  button={};


  constructor(public gt:genericCustomStoreService,public translate:TranslateService,public route:Router,
    public Objects: ObjectsService,public NavigationMenuService:NavigationMenuService) {    
    this.gt.$NavigationMenu.subscribe(data=>this.navigation_active_Id=data) 
    this.button = Objects.button;
    this.gt.getdropdown('NavigationMenu').subscribe((data:[])=>{
      this.parentId=data
    })
    if(this.navigation_active_Id!=0){
      this.gt.getdatabyid(this.navigation_active_Id,'NavigationMenu').subscribe((data:{permissionActions:[]})=>{
        this.navigationmenuform=data
        this.permissionAction=data.permissionActions
      })
    }      
  
  }
  
  ngOnInit(): void {}
  onInitNewRow(e: any) {
    e.data.id = 0
    e.data.navigationMenuId=this.navigation_active_Id
    }

  onFormSubmit(e:Event){
    if(this.navigation_active_Id==0){
      this.navigationmenuform.permissionActions=this.permissionAction
      this.NavigationMenuService.postnavigationmenu('NavigationMenu',this.navigationmenuform)
    }
    else{
      this.NavigationMenuService.editnavigationmenu('NavigationMenu',this.navigationmenuform)
    }
    this.route.navigate(['/NavigationMenu/all'])
  }


}
