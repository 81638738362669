import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentRoutingModule } from './shared-component-routing.module';
import { SharedComponentComponent } from './shared-component.component';
import { InsurancePopupComponent } from './component/insuracne-employee/insurance-popup/insurance-popup.component';
import { PersonalPopupComponent } from './component/personal-employee/personal-popup/personal-popup.component';
import { TaxPopupComponent } from './component/tax-employee/tax-popup/tax-popup.component';
import { InsuracneEmployeeComponent } from './component/insuracne-employee/insuracne-employee.component';
import { PersonalEmployeeComponent } from './component/personal-employee/personal-employee.component';
import { TaxEmployeeComponent } from './component/tax-employee/tax-employee.component';
import { SharedModule } from '../shared/modules/shared/shared.module';
import { AfairsSettingModule } from '../pages/hr/afairs-setting/afairs-setting.module';
import { WorksystemsettingDatagridComponent } from './component/worksystemsetting-datagrid/worksystemsetting-datagrid.component';
import { BackPageComponent } from './component/back-page/back-page.component';
import { PaymentMethodsComponent } from './component/payment-methods/payment-methods.component';
import { HomeTabComponent } from './component/home-tab/home-tab.component';
import { ReportFormComponent } from './component/report-form/report-form.component';
import { ReportDesignerComponent } from './component/report-designer/report-designer.component';
import { DxReportDesignerModule, DxReportViewerModule } from 'devexpress-reporting-angular';
import { ReportViewerComponent } from './component/report-form/report-viewer/report-viewer.component';
import { EmployeeSystemComponent } from './component/employee-system/employee-system.component';
import { DueDateStoreComponent } from './component/due-date/due-date-store.component';
import { GridStoreComponent } from './component/due-date/grid/grid.component';
import { AddComFromLookupComponent } from './component/add-com-from-lookup/add-com-from-lookup.component';
import { PrintReportComponent } from './component/report-form/print-report/print-report.component';
import { DxChartModule, DxPieChartModule } from 'devextreme-angular';
import { ShareAttachmentEmpComponent } from './component/share-attachment-emp/share-attachment-emp.component';
import { AttachmentsComponent } from './component/attachments/attachments.component';
import { ItemsSearchComponent } from './component/items-search/items-search.component';


@NgModule({
  declarations: [
    SharedComponentComponent,
    InsurancePopupComponent,
    PersonalPopupComponent,
    TaxPopupComponent,
    InsuracneEmployeeComponent,
    PersonalEmployeeComponent,
    TaxEmployeeComponent,
    WorksystemsettingDatagridComponent,
    BackPageComponent,
    PaymentMethodsComponent,
    HomeTabComponent,
    ReportFormComponent,
    ReportDesignerComponent,
    ReportViewerComponent,
    EmployeeSystemComponent,
    DueDateStoreComponent,
    GridStoreComponent,
    AddComFromLookupComponent,
    PrintReportComponent,
    ShareAttachmentEmpComponent,
    AttachmentsComponent,
    ItemsSearchComponent,
  ],
  imports: [
    CommonModule,
    SharedComponentRoutingModule,
    SharedModule,
    AfairsSettingModule,
    DxReportDesignerModule,
    DxReportViewerModule,
    DxChartModule,
    DxPieChartModule
  ],
  exports:[
    InsurancePopupComponent,
    PersonalPopupComponent,
    TaxPopupComponent,
    InsuracneEmployeeComponent,
    PersonalEmployeeComponent,
    TaxEmployeeComponent,
    WorksystemsettingDatagridComponent,
    BackPageComponent,
    PaymentMethodsComponent,
    HomeTabComponent,
    ReportFormComponent,
    ReportDesignerComponent,
    ReportViewerComponent,
    EmployeeSystemComponent,
    DueDateStoreComponent,
    AddComFromLookupComponent,
    ShareAttachmentEmpComponent,
    AttachmentsComponent,
    ItemsSearchComponent 
  ]
})
export class SharedComponentModule { }
