import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent, ResetPasswordFormComponent, CreateAccountFormComponent, ChangePasswordFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { DxDataGridModule, DxFormModule } from 'devextreme-angular';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { NavigationMenuComponent } from './pages/navigation-menu/navigation-menu.component';
import { EditNavigationMenuComponent } from './pages/navigation-menu/edit-navigation-menu/edit-navigation-menu.component';
import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { PrintReportComponent } from './shared-component/component/report-form/print-report/print-report.component';
import { CompanyComponent } from './pages/basic-data/company/company.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch:'full'
  },
  {
    path: 'tasks',
    component: TasksComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'profile',
    component: CompanyComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'serverError',
    component: ServerErrorComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'reset-password',
    component: ResetPasswordFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'create-account',
    component: CreateAccountFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'change-password/:recoveryCode',
    component: ChangePasswordFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'NavigationMenu/all',
    component: NavigationMenuComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'EditNavigationMenu',
    component: EditNavigationMenuComponent,
  },
  { path: 'print-report/:reportUrl', component: PrintReportComponent },
  {
    path: 'hr',
     loadChildren: () => import('./pages/hr/hr.module').then(m => m.HRModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'permission',
     loadChildren: () => import('./pages/permission/permission.module').then(m => m.PermissionModule),
    canActivate: [ AuthGuardService ]
  },
  { path: 'sharedComponent', loadChildren: () => import('./shared-component/shared-component.module').then(m => m.SharedComponentModule) },
  { path: 'Permission', loadChildren: () => import('./pages/permission/permission.module').then(m => m.PermissionModule) },
  { path: 'accounting', loadChildren: () => import('./pages/accounting/accounting.module').then(m => m.AccountingModule) },
  { path: 'basicInformation', loadChildren: () => import('./pages/basic-data/basic-data.module').then(m => m.BasicDataModule) },
  { path: 'stores', loadChildren: () => import('./stores/stores.module').then(m => m.StoresModule) },
  { path: 'sales', loadChildren: () => import('./pages/sales/sales.module').then(m => m.SalesModule) },
  { path: 'purchases', loadChildren: () => import('./pages/purchases/purchases.module').then(m => m.PurchasesModule) },
  { path: 'sales', loadChildren: () => import('./pages/sales/sales.module').then(m => m.SalesModule) },
  { path: 'clinics', loadChildren: () => import('./pages/clinics/clinics.module').then(m => m.ClinicsModule) },
  { path: 'Settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule) },
  { path: 'reports', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) },
  {path :'CDC', loadChildren : ()=> import(`./pages/cdc/cdc.module`).then(m=> m.CdcModule)},
  {path :"shipments", loadChildren :()=> import(`./pages/shipments/shipments.module`).then(m=>m.ShipmentsModule)},
  {path :"DocumentaryCycle", loadChildren :()=> import(`./pages/documentary-cycle/documentary-cycle.module`).then(m=>m.DocumentaryCycleModule)},
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true }), DxDataGridModule, DxFormModule],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [
    ProfileComponent,
    TasksComponent
  ]
})
export class AppRoutingModule { }
