import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AfairsSettingComponent } from './afairs-setting.component';
import { InsuranceSettingComponent } from './insurance-setting/insurance-setting.component';
import { PersonalSettingComponent } from './personal-setting/personal-setting.component';
import { TaxSettingComponent } from './tax-setting/tax-setting.component';
import { AddEditInsuranceSettingComponent } from './insurance-setting/add-edit-insurance-setting/add-edit-insurance-setting.component';
import { AddEditPersonalSettingComponent } from './personal-setting/add-edit-personal-setting/add-edit-personal-setting.component';
import { AddEditTaxSettingComponent } from './tax-setting/add-edit-tax-setting/add-edit-tax-setting.component';

const routes: Routes = [{ path: '', component: AfairsSettingComponent,
children:[
  {
    path:'Personal',
    component:PersonalSettingComponent
    },
  {
    path:'Insurance',
    component:InsuranceSettingComponent,
  },
  {
    path:'Tax',
    component:TaxSettingComponent
  },
  {
    path:'add-edit-insurance-setting',
    component:AddEditInsuranceSettingComponent
  },
  {
    path:'add-edit-EmployeeAfairs-Setting',
    component:AddEditPersonalSettingComponent
  },
  {
    path:'add-edit-TaxSchedule-Setting',
    component:AddEditTaxSettingComponent
  }
]}
];


@NgModule({
  
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AfairsSettingRoutingModule { }
