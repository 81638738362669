import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private hubConnection: signalR.HubConnection;
  messageReceived = new Subject<string>();

  constructor() {
    const  accessToken = localStorage?.getItem('token')
    const url = `${environment.apiRoot}/Chat?access_token=${accessToken}`
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .build();

    this.hubConnection.start().then(() => {
      console.log('SignalR connected');
      this.hubConnection.on('ReceiveMessage', (message) => {
        this.messageReceived.next(message);
      });
    }).catch(err => console.error(err.toString()));
  }

  sendMessage(message: string) {
    this.hubConnection.invoke('SendMessage', message).catch(err => console.error(err.toString()));
  }
}
