import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { HandelBackendValidationService } from '../shared/services/handel-backend-validation.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, public HandelBackendValidationService: HandelBackendValidationService, public transalte: TranslateService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
              console.log("Error Event");
            } else {
              console.log(
                `error status : ${error.status} ${JSON.stringify(error.error)}`
              );
              switch (error.status) {
                case 401:
                  this.router.navigateByUrl("/login");
                  break;
                case 403:
                  this.router.navigateByUrl("/unauthorized");
                  break;
                case 500:
                  notify({
                    message: this.transalte.instant('errors.serverError'),
                    height: 45,
                    width: 900,
                    minWidth: 150,
                    type: 'error',
                    displayTime: 2500,
                    animation: {
                      show: {
                        type: 'fade', duration: 100, from: 0, to: 1,
                      },
                      hide: { type: 'fade', duration: 40, to: 0 },
                    },
                  });

                  break;
                case 0:
                case 400:
                case 422:
                case 405:
                case 406:
                case 409:
                case 404:
                  this.handleAuthError(error);
                  break;
              }
            }
          } else {
            console.error("something else happened");
          }

          return throwError(error);
        })
      )
  }

  public handleAuthError(error: any) {
    console.log(error);

    let msg = "";
    if (error !== undefined && typeof error === "string") {
      msg = error;
    } else if (error.errors !== undefined && typeof error.errors === "string") {
      msg = error.errors;
    } else if (
      error.error.ErrorValidation !== undefined &&
      typeof error.error.ErrorValidation === "object" && error.error.ErrorValidation != null
    ) {


      // msg=error.error.ErrorValidation.Name + error.error.ErrorValidation.Name ;
      const er = error.error.ErrorValidation;
      let mess: string = "";
      for (let key in er) {
        if (er.hasOwnProperty(key)) {
          mess += er[key].toString();
          mess += '\n'
          mess += '   '
        }
      }
      msg = mess;
      console.log(msg)






      this.HandelBackendValidationService.HandelBackendValidation(error)
    }
    else {
      msg = error.error.Message
    }

    notify({
      message: msg,
      height: 45,
      width: 900,
      minWidth: 150,
      type: 'error',
      displayTime: 2500,
      animation: {
        show: {
          type: 'fade', duration: 100, from: 0, to: 1,
        },
        hide: { type: 'fade', duration: 40, to: 0 },
      },
    });

  }
}
