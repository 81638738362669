import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataShareService } from './data-share.service';

@Injectable({
  providedIn: 'root'
})
export class HandelBackendValidationService {
  constructor(public DataShareService:DataShareService) { }
  public HandelBackendValidation(error:HttpErrorResponse){
    const errors:any={};
    for(const key in error.error.ErrorValidation){
      if(Object.prototype.hasOwnProperty.call(error.error.ErrorValidation,key)){
        errors[key]=error.error.ErrorValidation[key]
      }
    }
    this.DataShareService.addServerError(errors)
    
  }
}
