
 <div class="faspinner" *ngIf="loading">
   <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator></div>
<div>
  <h6 class="logintitle">{{'loginComponent.title'|translate}}</h6>
  <form class="login-form" (submit)="onSubmit($event)">
    <dx-form [formData]="formData" [disabled]="loading">
  
      <dxi-item dataField="UserName" editorType="dxTextBox"
        [editorOptions]="{stylingMode: 'filled', placeholder:'loginComponent.username'|translate, mode: 'UserName' }"
        >
        <dxi-validation-rule type="required" message="{{'errors.required'|translate}}"></dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
  
      <dxi-item dataField="Password" editorType="dxTextBox"
        [editorOptions]="{ stylingMode: 'filled', placeholder:'loginComponent.Password'|translate, mode: 'Password' }">
        <dxi-validation-rule type="required" message="{{'errors.required'|translate}}"></dxi-validation-rule>
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
  
      <dxi-item dataField="rememberMe" editorType="dxCheckBox"
        [editorOptions]="{text:'loginComponent.remember'|translate, elementAttr: { class: 'form-text' } }">
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
  
      <dxi-item itemType="button">
        <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'signInTemplate'">
        </dxo-button-options>
      </dxi-item>
  
      <ng-container *dxTemplate="let item of 'signInTemplate'">
        <div>
          <span class="dx-button-text">
           
            {{'loginComponent.title'|translate}}
          </span>
        </div>
      </ng-container>
  
    </dx-form>
  </form>
</div>

