import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DataShareService {
  ServerErrorSubject=new BehaviorSubject(null)
  public ServerError$=this.ServerErrorSubject.asObservable()
  constructor() { }

  addServerError(errors:any){
  this.ServerErrorSubject.next(errors)
  }

  removeServerError(){
    this.ServerErrorSubject.next(null)
    }

}
