import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ObjectsService {

  public TypeValue = [
    {
      id:1,
      name:this.translatee.instant('tableinfo.valuechoose')
    },
    {
      id:2,
      name:this.translatee.instant('tableinfo.percentagechoose')
    }
  ]

  public TaxableStatus = [
    {
      id:1,
      name:this.translatee.instant('tableinfo.tax')
    },
    {
      id:2,
      name:this.translatee.instant('tableinfo.notax')
    }
  ]
  public InsuranceStatus = [
    {
      id:1,
      name:this.translatee.instant('tableinfo.insurance')
    },
    {
      id:2,
      name:this.translatee.instant('tableinfo.noinsurance')
    }
  ]

  public penalityReason = [
    {
      id:1,
      name:this.translatee.instant('tableinfo.penalityReason.Punishment')
    },
    {
      id:2,
      name:this.translatee.instant('tableinfo.penalityReason.Forgotattendance')
    },
    {
      id:3,
      name:this.translatee.instant('tableinfo.penalityReason.Forgetleaving')
    },
    {
      id:4,
      name:this.translatee.instant('tableinfo.penalityReason.absence')
    },
    {
      id:5,
      name:this.translatee.instant('tableinfo.penalityReason.other')
    },
  ]
  
  public button = {
    saveRowChanges: this.translatee.instant('tableinfo.savebutton'),
    cancelRowChanges: this.translatee.instant('tableinfo.cancelbutton'),
  }

  public businessType = [
    {
      id:1,
      name:this.translatee.instant('basic-info.company.Commercial'),

    }
  ]

  public employeeWorkStatus = [
    {
      id:1,
      name:this.translatee.instant('addemployee.onjob')
    },
    {
      id:2,
      name:this.translatee.instant('addemployee.pension')
    }
  ]
  
  public personalType = [
    {
      id:1,
      name:this.translatee.instant('addemployee.male')
    },
    {
      id:2,
      name:this.translatee.instant('addemployee.famale')
    }
  ]

  public delayRuleType = [
    {
      id:1,
      name:this.translatee.instant('workSystemSetting.DeductionRules.delayRuleType.minutetominute')
    },
    {
      id:2,
      name:this.translatee.instant('workSystemSetting.DeductionRules.delayRuleType.minutetovalue')
    },
    {
      id:3,
      name:this.translatee.instant('workSystemSetting.DeductionRules.delayRuleType.minutetohour')
    },
    {
      id:4,
      name:this.translatee.instant('workSystemSetting.DeductionRules.delayRuleType.minutetopercentage')
    },
  ]
  public durationType = [
    {
      id:1,
      name:this.translatee.instant('workSystemSetting.DeductionRules.durationType.all')
    },
    {
      id:2,
      name:this.translatee.instant('workSystemSetting.DeductionRules.durationType.beforeleavingdate')
    },
    {
      id:3,
      name:this.translatee.instant('workSystemSetting.DeductionRules.durationType.afterattendingdate')
    }
  ]
  public absenceRules = [
    {
      id:1,
      name:this.translatee.instant('workSystemSetting.DeductionRules.absenceRules.dayToDay')
    },
    {
      id:2,
      name:this.translatee.instant('workSystemSetting.DeductionRules.absenceRules.daytovalue')
    },
    {
      id:3,
      name:this.translatee.instant('workSystemSetting.DeductionRules.absenceRules.daytosalary')
    },
    {
      id:4,
      name:this.translatee.instant('workSystemSetting.DeductionRules.absenceRules.daytoPercentage')
    },
  ]
  public Attending_Leaving = [
    {
      id:1,
      name:this.translatee.instant('AttendingLeaving.Attending')
    },
    {
      id:2,
      name:this.translatee.instant('AttendingLeaving.Leaving')
    },
    {
      id:3,
      name:this.translatee.instant('AttendingLeaving.Attending_Leaving')
    }
    
  ]

  public cireChoice=[
    {
      id:1,
      name:this.translatee.instant('addemployee.tab4.total')
    },
    {
      id:2,
      name:this.translatee.instant('addemployee.tab4.main')
    },
    {
      id:3,
      name:this.translatee.instant('addemployee.tab4.s_value')
    }
  ]

  public accountNature=[
    {
      id:1,
      name:this.translatee.instant('accounting.Creditanddepit')
    },
    {
      id:2,
      name:this.translatee.instant('accounting.Credit')
    },
    {
      id:3,
      name:this.translatee.instant('accounting.depit')
    }
  ]

  public FinalAccountsTypes=[
    {
      id:1,
      name:this.translatee.instant('accounting.Assets')
    },
    {
      id:2,
      name:this.translatee.instant('accounting.Liabilities')
    },
    {
      id:3,
      name:this.translatee.instant('accounting.Income')
    },
    {
      id:4,
      name:this.translatee.instant('accounting.Outcome')
    }
  ]

  public AccountType=[
    {
      id:101,
      name:this.translatee.instant('accounting.Customers'),
      finalAccountsTypes:1
    },
    {
      id:106,
      name:this.translatee.instant('accounting.Safe'),
     finalAccountsTypes:1
    },
    {
      id:103,
      name:this.translatee.instant('accounting.Bank'),
     finalAccountsTypes:1
    },
    {
      id:104,
      name:this.translatee.instant('accounting.FixedAssets'),
     finalAccountsTypes:1
    },
    {
      id:105,
      name:this.translatee.instant('accounting.Cheeque'),
     finalAccountsTypes:1
    },
    {
      id:102,
      name:this.translatee.instant('accounting.OtherAssets'),
     finalAccountsTypes:1
    },
    {
      id:201,
      name:this.translatee.instant('accounting.Vendors'),
     finalAccountsTypes:2
    },
    {
      id:203,
      name:this.translatee.instant('accounting.Capital'),
     finalAccountsTypes:2
    },
    {
      id:202,
      name:this.translatee.instant('accounting.OtherLiabilities'),
     finalAccountsTypes:2
    },
    {
      id:204,
      name:this.translatee.instant('accounting.Allocations'),
     finalAccountsTypes:2
    },
    {
      id:205,
      name:this.translatee.instant('accounting.Profit'),
     finalAccountsTypes:2
    },
    {
      id:301,
      name:this.translatee.instant('accounting.OutComes'),
     finalAccountsTypes:3
    },
    {
      id:302,
      name:this.translatee.instant('accounting.OtherOutComes'),
     finalAccountsTypes:3
    },
    {
      id:303,
      name:this.translatee.instant('accounting.CurrencySpreads'),
     finalAccountsTypes:3
    },
    {
      id:401,
      name:this.translatee.instant('accounting.InComes'),
     finalAccountsTypes:4
    },
    {
      id:402,
      name:this.translatee.instant('accounting.OtherInComes'),
     finalAccountsTypes:4
    }
  ]
  
  public fixedOrChangableType = [
    {
      id:0,
      name:this.translatee.instant('salary.fixed')
    },
    {
      id:1,
      name:this.translatee.instant('salary.changable')
    } 
  ]

  public WeekDaysList=
    [
    {"id":0,"nameAr":this.translatee.instant("WeekDaysList.Sunday")},
    {"id":1,"nameAr":this.translatee.instant("WeekDaysList.Monday")},
    {"id":2,"nameAr":this.translatee.instant("WeekDaysList.Tuesday")},
    {"id":3,"nameAr":this.translatee.instant("WeekDaysList.Wednesday")},
    {"id":4,"nameAr":this.translatee.instant("WeekDaysList.Thursday")},
    {"id":5,"nameAr":this.translatee.instant("WeekDaysList.Friday")},
    {"id":6,"nameAr":this.translatee.instant("WeekDaysList.Saturday")}
   ]
  

   public MonthsList:any=
   [
   {"id":1,"nameAr":this.translatee.instant("MonthsList.January") },
   {"id":2,"nameAr":this.translatee.instant("MonthsList.February" )},
   {"id":3,"nameAr":this.translatee.instant("MonthsList.March")},
   {"id":4,"nameAr":this.translatee.instant("MonthsList.April")},
   {"id":5,"nameAr":this.translatee.instant("MonthsList.May")},
   {"id":6,"nameAr":this.translatee.instant("MonthsList.June")},
   {"id":7,"nameAr":this.translatee.instant("MonthsList.July")},
   {"id":8,"nameAr":this.translatee.instant("MonthsList.August")},
   {"id":9,"nameAr":this.translatee.instant("MonthsList.September")},
   {"id":10,"nameAr":this.translatee.instant("MonthsList.October")},
   {"id":11,"nameAr":this.translatee.instant("MonthsList.November")},
   {"id":12,"nameAr":this.translatee.instant("MonthsList.December")}
  ]

  // items

   public ageType=
   [
   {"id":1,"nameAr":this.translatee.instant("approval.year")},
   {"id":2,"nameAr":this.translatee.instant("approval.month")},
 
  ]

  public businessTyp=
  [
  {"id":1,"nameAr":this.translatee.instant("purchases.Bussines")},
  {"id":2,"nameAr":this.translatee.instant("purchases.NaturalPerson")},
  {"id":3,"nameAr":this.translatee.instant("purchases.Foreigner")},

 ]
 public Vendortabs= [
  {
    id: 0,
    text: this.translatee.instant('purchases.commercialdata'),
  },
  {
    id: 1,
    text: this.translatee.instant('purchases.vendoraccount'),
  },
  {
    id: 2,
    text: this.translatee.instant('purchases.responsiblePeople'),
  },
  {
    id: 3,
    text: this.translatee.instant('purchases.vendorBranches'),
  },
  {
    id: 4,
    text: this.translatee.instant('purchases.vendorDueDates'),
  },
  {
    id: 5,
    text: this.translatee.instant('purchases.addedTaxies'),
  },
];
public VoucherVendor= [
  {
    id: 0,
    text: this.translatee.instant('accounting.debitDueDatesVendor'),
  },
  {
    id: 1,
    text: this.translatee.instant('accounting.creditDueDatesVendor'),
  },
  {
    id: 2,
    text: this.translatee.instant('accounting.debitPaidDueDatesVendor'),
  },
  {
    id: 3,
    text: this.translatee.instant('accounting.creditPaidDueDatesVendor'),
  },
  {
    id: 4,
    text: this.translatee.instant('accounting.paymentMethods'),
  }
];

public VoucherCustomer= [
  {
    id: 0,
    text: this.translatee.instant('accounting.debitDueDatesCustomer'),
  },
  {
    id: 1,
    text: this.translatee.instant('accounting.creditDueDatesCustomer'),
  },
  {
    id: 2,
    text: this.translatee.instant('accounting.debitPaidDueDatesCustomer'),
  },
  {
    id: 3,
    text: this.translatee.instant('accounting.creditPaidDueDatesCustomer'),
  },
  {
    id: 4,
    text: this.translatee.instant('accounting.paymentMethods'),
  }
];

public Voucher= [
  {
    id: 0,
    text: this.translatee.instant('accounting.debitDueDatesVendor'),
  },
  {
    id: 1,
    text: this.translatee.instant('accounting.creditDueDatesVendor'),
  },
  {
    id: 2,
    text: this.translatee.instant('accounting.debitPaidDueDatesVendor'),
  },
  {
    id: 3,
    text: this.translatee.instant('accounting.creditPaidDueDatesVendor'),
  },
];

public OUT_Voucher= [
  {
    id: 0,
    text: this.translatee.instant('accounting.debitDueDatesCustomer'),
  },
  {
    id: 1,
    text: this.translatee.instant('accounting.creditDueDatesCustomer'),
  },
  {
    id: 2,
    text: this.translatee.instant('accounting.debitPaidDueDatesCustomer'),
  },
  {
    id: 3,
    text: this.translatee.instant('accounting.creditPaidDueDatesCustomer'),
  }
];

public dueDateType= [
  {
    id: 1,
    text: this.translatee.instant('accounting.BeginBalance'),
  },
  {
    id: 2,
    text: this.translatee.instant('accounting.Invoice'),
  },
  {
    id: 3,
    text: this.translatee.instant('accounting.Paper'),
  },
]

discountType=[
  { id: 0, text: this.translatee.instant('purchases.nofound') },
  { id: 1, text: this.translatee.instant('tableinfo.percentagechoose') },
  { id: 2, text: this.translatee.instant('tableinfo.valuechoose') },
 ]

 debitLimit=[
  { id: 1, text: this.translatee.instant('purchases.nolimit') },
  { id: 2, text: this.translatee.instant('purchases.Stop') },
  { id: 3, text: this.translatee.instant('purchases.warning') },
  { id: 4, text: this.translatee.instant('purchases.statement') },

 ]

  constructor(public translatee: TranslateService) { }


}
