import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { InsuranceSetting } from 'src/app/Interfaces/hr/insurance-setting';
import { AfairsSettingService } from 'src/app/shared/services/hr/afairs-setting.service';
import { NotifyService } from 'src/app/shared/services/hr/notify.service';

@Component({
  selector: 'app-add-edit-insurance-setting',
  templateUrl: './add-edit-insurance-setting.component.html',
  styleUrls: ['./add-edit-insurance-setting.component.scss']
})
export class AddEditInsuranceSettingComponent implements OnInit, OnDestroy {
  insurancesettingform: any
  insuranceid: number;
  buttonOptions = {
    text: this.translate.instant('tableinfo.savebutton'),
    type: "default",
    useSubmitBehavior: true,
  };
  savebutton: string;
  subscription: Subscription;
  //loading
  loading: boolean = false
  constructor(public translate: TranslateService, private cdref: ChangeDetectorRef,public NotifyService:NotifyService,
    public http: HttpClient,public _AfairsSettingService: AfairsSettingService,public router:Router) {
    this.getdata()
  }
  ngOnInit() { }
  getdata() {
    this.loading = true
    this.subscription = this._AfairsSettingService.$INSURANCE_ID.subscribe(res => {
      this.insuranceid = res
      if(this.insuranceid!=0){
      this._AfairsSettingService.getinsurancesetting(this.insuranceid).subscribe((res: InsuranceSetting) => {
        this.insurancesettingform = res
        this.loading = false
        this.cdref.detectChanges()
      })
    }
    else{
      this.insurancesettingform={}
      this.loading=false
    }
    })
  }
  onFormSubmit(e: any): void {
    e.preventDefault();
    if (this.insuranceid != 0) {
      this._AfairsSettingService.putinsurancesetting(this.insurancesettingform).subscribe(res=>{
        this.router.navigate(['hr/afairsesetting/Insurance'])
        this.NotifyService.notifyfun('edit')
      })

    }
    else {
      this._AfairsSettingService.addinsurancesetting(this.insurancesettingform).subscribe(res=>{
        this.router.navigate(['hr/afairsesetting/Insurance'])
        this.NotifyService.notifyfun('add')
      })
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
