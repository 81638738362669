import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { TranslationService } from './shared/services/i18n/translation.service';
import { loadMessages } from 'devextreme/localization';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { genericCustomStoreService } from './shared/services/genericCustomStore.service';
import { SignalRService } from './signal-r.service';
import { ThemeService } from './theme.service';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }
  languages = ["en", "ar"];
  LOCALIZATION_LOCAL_STORAGE_KEY = "Language";
  browserLang: any;
  currentLang: any;
  message: string = "";
  iconClass: string = 'icon-default';

  constructor(private themeService: ThemeService,private authService: AuthService, public route: ActivatedRoute, private url: LocationStrategy,
    private screen: ScreenService, public appInfo: AppInfoService, public router: Router,public gt:genericCustomStoreService,
    private translate: TranslateService,private signalrservice:SignalRService,
    public translationService: TranslationService) { 
      this.translate.addLangs(this.languages);
     this.currentLang = localStorage.getItem(
      this.LOCALIZATION_LOCAL_STORAGE_KEY
    );
    if (this.currentLang !== null && this.currentLang !== undefined) {
      this.translate.use(this.currentLang);

      let direction =
        localStorage.getItem(this.LOCALIZATION_LOCAL_STORAGE_KEY) === "ar"
          ? "rtl"
          : "ltr";
      document.documentElement.dir = direction;
      document.documentElement.lang = this.currentLang;

      let dirclass = localStorage.getItem(this.LOCALIZATION_LOCAL_STORAGE_KEY) === "ar"
        ? "dx-rtl"
        : "";

      let getMain = document.getElementsByTagName("html")[0];
      getMain.setAttribute("lang", this.currentLang);
      getMain.setAttribute("class", dirclass);
    } else {
      this.browserLang = this.translate.getBrowserLang();
      localStorage.setItem(
        this.LOCALIZATION_LOCAL_STORAGE_KEY,
        this.browserLang
      );
      this.translate.use(this.browserLang);
      this.translate.setDefaultLang(this.browserLang);
      window.location.reload();
    }

  
    // get routing
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     console.log((event as NavigationStart).url);
    //     let sessiondata:any = sessionStorage.getItem("data");
    //     let data = JSON.parse(sessiondata);
    //      data = data?.navihationMenu
    //     //make parent and child from data
    //     if (data) {
    //       let activePath=data.find((ele: any) => {
    //         return `/${ele.externalUrl}` == (event as NavigationStart).url
    //       })
    //       localStorage.setItem('activePath',activePath)
    //     }
    //   }
    // });

    this.signalrservice.messageReceived.subscribe((message) => {
      this.message = message;
      if (this.message != "" && this.message != undefined) {
        this.iconClass = "icon-active"
      }
      console.log(this.message);
    });

  }
  ngOnInit(){
    this.themeService.applyTheme();
  }
  isAuthenticated() {
    return this.authService.loggedIn
  }

}
