import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    currentLanguage:any;

    constructor(private router: Router) {
        this.currentLanguage=window.localStorage.getItem('Language')
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (sessionStorage.getItem('token') != null) {
              const cloneReq = req.clone({ 
                setHeaders: { Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                'Accept-Language': this.currentLanguage,
                'Access-Control-Allow-Origin': '*'}
                 });

               return next.handle(cloneReq).pipe(
                tap(
                    succ => { },
                    err => {
                        if (err.status == 401){
                            console.log(err);
                            sessionStorage.removeItem('token');
                            this.router.navigateByUrl('/login-form');
                        }
                    }
                )
            )
        }
        else{
         this.router.navigateByUrl('/login-form');
        }
            return next.handle(req.clone());
    }
}
