import { Component } from '@angular/core';
import { ObjectsService } from 'src/app/shared/services/hr/objects.service';
import { loadMessages } from 'devextreme/localization';
import { TranslateService } from '@ngx-translate/core';
import { genericCustomStoreService } from 'src/app/shared/services/genericCustomStore.service';

@Component({
  selector: 'app-super',
  templateUrl: './super.component.html',
})
export class SuperComponent  {
  button = {}
  addpopup: string = ''
  LOCALIZATION_LOCAL_STORAGE_KEY = "Language";
  currentLang: any;
  editingMode:boolean=false
  loading: boolean = false
  constructor(public Objects: ObjectsService,public translate: TranslateService,public gt: genericCustomStoreService) {
    this.button = Objects.button;
    this.currentLang = localStorage.getItem(
      this.LOCALIZATION_LOCAL_STORAGE_KEY
    );
    loadMessages({
      en: { "Yes": this.translate.instant('personal.yes'), "No": this.translate.instant('personal.no') }
    });
   }

    onInitNewRow(e: Event) {
    this.addpopup = this.translate.instant('tableinfo.addbutton')
    this.editingMode=false
  }

   updateRow(options: any) {
    options.newData = Object.assign(options.oldData, options.newData);
  }

  addrowfun(name: string) {
    return this.gt.addrow(name)
  }

}
