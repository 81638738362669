import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from 'src/app/shared/services/http-error-handler.service';

export interface IUser {
  userName: string;

 
  avatarUrl?: string
}

const defaultPath = '/';

@Injectable()
export class AuthService {
  private _user: IUser | null;

  currentLanguage : any

  headers: any
  get loggedIn(): boolean {
    return !!sessionStorage.getItem('token')?.toString();
  }

  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  private handleError: HandleError;

  constructor(private router: Router,private httpClient: HttpClient,httpErrorHandler: HttpErrorHandler){
    this.handleError = httpErrorHandler.createHandleError('ObjectService');
    this.currentLanguage=window.localStorage.getItem('Language')
 
   


  }

  // async logIn(formData:any) {

  //   try {

  //     this.httpClient.post(`${environment.apiRoot}/api/Auth/token`,formData).pipe(

  //       catchError(this.handleError('updateError', formData))
  //     ).toPromise().then((message:any)=>{
  //         if(message.isAuthenticated)
  //         {
  //           if(message.message==null){
  //             sessionStorage.setItem('data',JSON.stringify(message))
  //             sessionStorage.setItem('token', message.token);
  //             sessionStorage.setItem('username', message.username);

  //             this._user = {userName:message.userName };
  //             this.router.navigate(['/home']);

  //           }else
  //           {
  //             console.log(message)
  //           }
  //         }

  //     });
  //      this.router.navigate([this._lastAuthenticatedPath]);
  //     return {
  //       isOk: true,
  //       data: this._user
  //     };
  //   }
  //   catch {
  //     return {
  //       isOk: false,
  //       message: "Authentication failed"
  //     };
  //   }
  // }

  async logIn(formData: any) {
    const language = window.localStorage.getItem('Language') || 'en';

    // Set the Accept-Language header dynamically
    const headers = new HttpHeaders({
      'Accept-Language': language
    });
    try {
      const response = await this.httpClient.post(
        `${environment.apiRoot}/api/Auth/token`,
        formData,{ headers: headers }
      ).pipe(
        catchError(this.handleError('updateError', formData))
      ).toPromise();
  
      if (response.isAuthenticated) {
        if (!response.message) {
          sessionStorage.setItem('data', JSON.stringify(response));
          sessionStorage.setItem('token', response.token);
          sessionStorage.setItem('username', response.username);
  
          this._user = { userName: response.userName };
  
          // Navigate to home only after setting session data.
          this.router.navigate(['/home']);
        } else {
          console.log(response.message);
        }
        return {
          isOk: true,
          data: this._user
        };
      } else {
        return {
          isOk: false,
          message: "Authentication failed"
        };
      }
    } catch {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
  }
  

  async getUser() {
    try {
      // Send request
      this._user = { userName:sessionStorage.getItem('username')?.toString() as string};
      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false,
        data: null
      };
    }
  }

  async createAccount(email: string, password: string) {
    try {
      // Send request
      console.log(email, password);

      this.router.navigate(['/create-account']);
      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }

  async changePassword(email: string, recoveryCode: string) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    };
  }

  async resetPassword(email: string) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  }

  async logOut() {
    this._user = null;
    localStorage.removeItem('activePath')
    this.router.navigate(['/login-form']);
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }


  canActivate(route: ActivatedRouteSnapshot): boolean {

   // debugger
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      'login-form',
      'reset-password',
      'create-account',
      'change-password/:recoveryCode'
    ].includes(route.routeConfig?.path || defaultPath);

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login-form']);
    }

    if (isLoggedIn) {
      this.authService.lastAuthenticatedPath = route.routeConfig?.path || defaultPath;
    }

    return isLoggedIn || isAuthForm;
  }
}
