import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckValidation {

  constructor(private httpClient:HttpClient) { }



  asyncCheckNameValue(params:string,id:number,url:string,CheckName='CheckName',name='NameAr') {
    return new Promise((resolve, reject) => {

        this.httpClient.get(`${environment.apiRoot}/api/${url}/${CheckName}?id=${id}&&${name}=${params}`).toPromise()
            .then((res: any) => {
            resolve(!res);
        })
        .catch(error => {
            console.error("Server-side validation error", error);

            reject("Cannot contact validation server");
        });

    });

  }
  asyncCheckNationalValue(params:string,id:number) {
    return new Promise((resolve, reject) => {

        this.httpClient.get(`${environment.apiRoot}/api/Employee/CheckNationalId?id=${id}&&NationalID=${params}`).toPromise()
            .then((res: any) => {
            resolve(!res);
        })
        .catch(error => {
            console.error("Server-side validation error", error);

            reject("Cannot contact validation server");
        });

    });

  }
  asyncCheckPrintValue(params:string,id:number) {
    return new Promise((resolve, reject) => {

        this.httpClient.get(`${environment.apiRoot}/api/Employee/CheckFingerPrintNumber?id=${id}&&FingerPrintNumber=${params}`).toPromise()
            .then((res: any) => {
            resolve(!res);
        })
        .catch(error => {
            console.error("Server-side validation error", error);

            reject("Cannot contact validation server");
        });

    });

  }

  asyncCheckCodeValue(params:string,id:number,data:string,code='Code',CheckCode='CheckCode') {
    return new Promise((resolve, reject) => {
        this.httpClient.get(`${environment.apiRoot}/api/${data}/${CheckCode}?id=${id}&&${code}=${params}`).toPromise()
            .then((res: any) => {
            resolve(!res);
        })
        .catch(error => {
            console.error("Server-side validation error", error);

            reject("Cannot contact validation server");
        });

    });
  }

  asyncCheckNumberValue(params:string,id:number,data:string,Number='Number') {
    return new Promise((resolve, reject) => {
        this.httpClient.get(`${environment.apiRoot}/api/${data}/CheckNumber?id=${id}&&${Number}=${params}`).toPromise()
            .then((res: any) => {
            resolve(!res);
        })
        .catch(error => {
            console.error("Server-side validation error", error);

            reject("Cannot contact validation server");
        });

    });

  }


}
