<ng-content ></ng-content>
<div class="menu-container" style="flex-direction: column; align-items: center;">
  <dx-tree-list style="margin-bottom: 2rem;height: 90vh;"
  id="sidemenu"
  [dataSource]="lists"
  keyExpr="id"
  parentIdExpr="parentId"
  [rootValue]=null
  [showRowLines]="false"
  [showBorders]="false"
  [columnAutoWidth]="true"
  [focusedRowEnabled]="true"
  [autoExpandAll]="false"
  width="100%"
  [showColumnHeaders]="false"
  [rtlEnabled]="currentLang=='ar'?true:false"
  [elementAttr]="{class: 'side' }"
  [(focusedRowKey)]="focusedRowKey"
  (onFocusedRowChanged)="onFocusedRowChanged($event)"
>
<dxo-search-panel
[visible]="true"
[width]="200"
placeholder="Search..."
></dxo-search-panel>
<dxi-column
[dataField]="currentLang=='ar'?'nameAr':'nameEn'" cellTemplate="employeeTemplate"
>
</dxi-column> 
<div *dxTemplate="let options of 'employeeTemplate'; let i = index">
  <div style="display: flex;display: flex;gap: 15px;align-items: center;margin: 0px 12px;">
    <!-- <i [class]="options.data.styleClass" [style.rotate]="(currentLang=='en' && options.data.styleClass.includes('fa-caret-left'))?'180deg':'360deg'"></i> -->
    <h6 style="text-decoration: none;color: #000;margin: 0;font-size: 12px;">
      {{currentLang=='ar'?options.data.nameAr:options.data.nameEn}}
    </h6>
  </div>
</div>
</dx-tree-list>
  </div>

