import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { MessageResponse } from 'src/app/Interfaces/MessageResponse';
import { NotifyService } from 'src/app/shared/services/hr/notify.service';
import { RequestProperty } from 'src/app/Interfaces/hr/requestproperties';

@Injectable({
  providedIn: 'root'
})
export class publicSettingService {
  customDataSource: any;

  $ACTIVE_ID = new BehaviorSubject<number>(0)
  $ACTIVE_ReFRESH = new BehaviorSubject<boolean>(false)

  //permission header system component
  $PERMISSION_ID = new BehaviorSubject<number>(0)
  $PERMISSION_ReFRESH = new BehaviorSubject<boolean>(false)

  //holiday header system component
  $Holiday_ID = new BehaviorSubject<number>(0)
  $Holiday_Edit_Start = new BehaviorSubject<boolean>(false)
  $Holiday_ReFRESH = new BehaviorSubject<boolean>(false)

  //job title structure
  $JOB_TITLE_ID = new BehaviorSubject<number>(0)

  //functional structure
  $FUNCTIONAL_STRUCTURE_ID = new BehaviorSubject<number>(0)

  //extraTimeRule
  $ETRRA_TIME = new BehaviorSubject<number>(0)

  //branches
  $BRANCH_ID = new BehaviorSubject<number>(0)
  //  taxies
  $TAXIES_ID = new BehaviorSubject<number>(0)
  //items
  $ITEMS_ID = new BehaviorSubject<number>(0)
  $ITEMS_UNITS_DATA = new BehaviorSubject<{}>({})

  //JOUNRAL_ENTRY_ID
  $JOUNRAL_ENTRY_ID = new BehaviorSubject<number>(0)

  //JOUNRAL_ENTRY_ID_FIRST_BALANCE
  $JOUNRAL_ENTRY_ID_FIRST_BALANCE = new BehaviorSubject<number>(0)

  //fixed assets
  $FIXED_ASSETS = new BehaviorSubject<number>(0);
  $BUY_FIXED_ASSETS = new BehaviorSubject<number>(0);
  $SELL_FIXED_ASSETS = new BehaviorSubject<number>(0);
  $RENEW_FIXED_ASSETS = new BehaviorSubject<number>(0);
  $TRACH_FIXED_ASSETS = new BehaviorSubject<number>(0);
  $Deprecations_FIXED_ASSETS = new BehaviorSubject<number>(0);
  $REPLACE_FIXED_ASSETS = new BehaviorSubject<number>(0);

  //reports
  $REPORT_URL = new BehaviorSubject<string>('');


  //EmployeeRequest
  $EmployeeRequest_ID = new BehaviorSubject<number>(0)


  $Requestproperties = new BehaviorSubject<RequestProperty[]>([])
  

  
  constructor(private http: HttpClient, public NotifyService: NotifyService) { }

  submit(name: string) {
    this.customDataSource = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => { 
        return lastValueFrom(this.http.get(`${environment.apiRoot}/api/${name}/all`))
          .catch(() => { throw 'Data loading error' });
      },

      remove: (key) => {
        return this.http.delete(`${environment.apiRoot}/api/${name}/Delete?id=` + encodeURIComponent(key)).toPromise().then((message: MessageResponse) => {
          this.NotifyService.notifyfun('delete')

        })
      }
    })
  }
  getAll(name: string) {
    return this.http.get(`${environment.apiRoot}/api/${name}/all`)
  }
  byKey(name: string, key: any) {
    return this.http.get(`${environment.apiRoot}/api/${name}/GetById/${key}`)
  }

  update(name: string, data: any): Observable<any> {
    console.log("api",data)
    return this.http.put(`${environment.apiRoot}/api/${name}/Update`, data)
  }
  add(name: string, data: any) {
    return this.http.post(`${environment.apiRoot}/api/${name}/Add`, data)
  }
  getAssetsData(id: number): Observable<any> {
    return this.http.get(`${environment.apiRoot}/api/SharedFixedAssets/GetSharedFixedAssetById?id=${id}`)
  }
  CalculateFixedAssetDeprecation(body: {}) {
    return this.http.post(`${environment.apiRoot}/api/FixedAssetsDeprecations/CalculateFixedAssetDeprecation`, body)
  }

  GetRequestPropertiesForRequest( key: any) {
    return this.http.get(`${environment.apiRoot}/api/EmployeeRequestProperties/GetRequestPropertiesForRequest/${key}`)
  }

  //custody Services
  setNoAvilableCustody (id:number, body?:{}) {
    return this.http.post(`${environment.apiRoot}/api/Custody/SetNotAvailable?id=${id}`, body)
  }
  setAvilableCustody (id:number) {
    return this.http.delete(`${environment.apiRoot}/api/Custody/SetAvailable?id=${id}`)
  }

  //custody transaction
  createCustody (data:any) {
    return this.http.post(`${environment.apiRoot}/api/CustodyEmployeeTransactions/CreateReturn`, data)
  }
  deleteCustody (id:number) {
    return this.http.delete(`${environment.apiRoot}/api/CustodyEmployeeTransactions/DeleteReturn?id=${id}`)
  }

  //custodyApproved
  ApprovedCustody (data:any) {
    return this.http.post(`${environment.apiRoot}/api/EmployeeCustodyRequestAction/Create`, data)

  }

  getheaderfilter(data:any){

    return this.http.post(`${environment.apiRoot}/api/Fillters/FilterSalaryHeader`, data)

  }
}


