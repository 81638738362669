<div class="faspinner" *ngIf="loading">
    <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
  </div>
  
  <div id="form-container" *ngIf="!loading">
      <form action="" (submit)="onFormSubmit($event)">
        <dx-form id="form" 
        [(formData)]="insurancesettingform"
        labelMode="outside" 
        labelLocation="top"
        [showColonAfterLabel]="true"
        style="padding: 1rem 2rem;margin: 2rem 0rem;">
          <dxi-item itemType="group" [colCount]="12">
            <dxi-item dataField="nameAr" [colSpan]="6">
              <dxo-label template="{{'tableinfo.NameAr'|translate}}"></dxo-label>
              <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="nameEn" [colSpan]="6">
              <dxo-label template="{{'tableinfo.NameEn'|translate}}"></dxo-label>
              <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="minimum" editorType="dxNumberBox" [colSpan]="6">
              <dxo-label template="{{'insurance.MinimumInsurance'|translate}}"></dxo-label>
              <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="maximum" editorType="dxNumberBox" [colSpan]="6">
              <dxo-label template="{{'insurance.Maximuminsurance'|translate}}"></dxo-label>
              <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0" >
              </dxi-validation-rule>
            </dxi-item>
    
          <dxi-item itemType="group" [colCount]="12" [colSpan]="12" caption="{{'insurance.wages'|translate}}" >
            <dxi-item dataField="underSixty_EmployeeRate" editorType="dxNumberBox" [colSpan]="4">
              <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
              </dxi-validation-rule>
                <dxo-label template="{{'insurance.employeeratio'|translate}}"></dxo-label>
              </dxi-item>
        
              <dxi-item dataField="underSixty_CompanyRate" editorType="dxNumberBox" [colSpan]="4" >
                <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
                </dxi-validation-rule>
                <dxo-label template="{{'insurance.companyratio'|translate}}"></dxo-label>
              </dxi-item>
  
              <dxi-item  editorType="dxNumberBox" [colSpan]="4" [editorOptions]="{readOnly :true, value:(insurancesettingform?.underSixty_EmployeeRate + insurancesettingform?.underSixty_CompanyRate) }">
                  <dxo-label template="{{'insurance.Total'|translate}}"></dxo-label>
                  <dxi-validation-rule type="range" message="{{'errors.insuranceTotal'|translate}}" [min]="0" [max]="100">
                  </dxi-validation-rule>
                </dxi-item>
                
              </dxi-item>
              <dxi-item itemType="group" [colCount]="12" caption="{{'insurance.60years'|translate}}" [colSpan]="12">
              <dxi-item dataField="aboveSixty_EmployeeRate" editorType="dxNumberBox" [colSpan]="4">
                <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
                </dxi-validation-rule>  
                <dxo-label template="{{'insurance.employeeratio'|translate}}"></dxo-label>
              </dxi-item>
        
              <dxi-item dataField="aboveSixty_CompanyRate" editorType="dxNumberBox" [colSpan]="4">
                <dxi-validation-rule type="range" message="{{'errors.min'|translate}}" [min]="0">
                </dxi-validation-rule>
                <dxo-label template="{{'insurance.companyratio'|translate}}"></dxo-label>
              </dxi-item>
  
              <dxi-item  editorType="dxNumberBox" [colSpan]="4" [editorOptions]="{readOnly :true,value:(insurancesettingform?.aboveSixty_EmployeeRate+insurancesettingform?.aboveSixty_CompanyRate) }">
                  <dxo-label template="{{'insurance.Total'|translate}}"></dxo-label>
                  <dxi-validation-rule type="range" message="{{'errors.insuranceTotal'|translate}}" [min]="0" [max]="100">
                  </dxi-validation-rule>
                </dxi-item>
        
          </dxi-item>
  
          <dxi-item [editorOptions]="{disabled:savebutton=='show'}"
          itemType="button"
          [buttonOptions]="buttonOptions"
         >
          </dxi-item>
    
        </dxi-item>
        </dx-form>
      </form>
      </div>
      