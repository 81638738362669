import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportViewerComponent } from '../report-viewer/report-viewer.component';
import { genericCustomStoreService } from 'src/app/shared/services/genericCustomStore.service';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html',
  styleUrls: ['./print-report.component.scss']
})
export class PrintReportComponent implements OnInit ,AfterViewInit  {

  idvalue: string | null = null;
  reportUrl: string  = "";

  ids: number[] = []

  filters : any

  @ViewChild(ReportViewerComponent, { static: false })
  viewer!: ReportViewerComponent;
  constructor(private route: ActivatedRoute, private gt : genericCustomStoreService ) { }
  ngAfterViewInit(): void {
    this.viewer.submitParameter(this.filters,this.reportUrl)
  }

  ngOnInit(): void {
     this.gt.$Ids.subscribe((res:any) => {

      this.ids = res

     
      this.reportUrl = this.route.snapshot.paramMap.get('reportUrl') ?? "";
      this.filters = {
       id: this.ids
  
      }

      console.log(this.filters)
     })
 

     

   
  }

  sendtomail(){
    
    var result = "";
     if(this.filters != null)
     {
       result = '?' + new URLSearchParams(this.filters).toString();
    }
     else{
       result = ""
     }
    
     

    this.gt.sendreportmail(this.reportUrl + result).subscribe((res:any)=> {

       

    })



  }



}
