<div class="publicsetting" style="padding: 0rem 3rem;">
    <h2 class="content-block title  animate__animated animate__fadeIn">{{'basic-info.company.name'|translate}}</h2>
    <dx-data-grid class="dx-card wide-card" 
    [dataSource]="customDataSource" 
    [showBorders]="false"
    [focusedRowEnabled]="true" 
    [focusedRowIndex]="0"
   [allowColumnResizing]="true"
  [columnAutoWidth]="true"
    [columnHidingEnabled]="true"
    [showColumnLines]="false"
    [rowAlternationEnabled]="true"
    [showRowLines]="false"
    [showBorders]="false"
    [errorRowEnabled]="false"
    [rtlEnabled]="currentLang=='ar'?true:false"
      (onRowUpdating)="updateRow($event)" (onInitNewRow)="onInitNewRow($event)"
      (onEditingStart)="onEditingStart($event)">
      <dxo-editing mode="popup" [useIcons]="false" [allowAdding]="true" [allowDeleting]="false" [allowUpdating]="true"
        [texts]="button">
        <dxo-popup [title]="addpopup" [showTitle]="true" [width]="700" [height]="525" [hideOnOutsideClick]="true">
        </dxo-popup>
        <dxo-texts [addRow]="addrowfun('basic-info.company.name')"
          confirmDeleteMessage="{{'tableinfo.deletebutton'|translate}}">
        </dxo-texts>
      </dxo-editing>
        <dxo-paging [pageSize]="10"> </dxo-paging>
    <dxo-pager
      [visible]="true"
      allowedPageSizes="true"
      displayMode="full"
      [showPageSizeSelector]="true"
      [showInfo]="false"
      [showNavigationButtons]="true"
    >
    </dxo-pager>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxi-column dataField="nameAr"  caption="{{'basic-info.company.companyName'|translate}}">
      <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="businessType" caption="{{'basic-info.company.businessType'|translate}}">
        <dxo-lookup
        [dataSource]="businessType"
        valueExpr="id"
        displayExpr="name"
      ></dxo-lookup>
      <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="numberId" caption="{{'basic-info.company.numberId'|translate}}">
        <dxi-validation-rule type="stringLength" [min]="9" [max]="9"
        message="{{'errors.companyLength'|translate}}"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="taxpayerActivityCode" caption="{{'basic-info.company.taxpayerActivityCode'|translate}}">
        <dxi-validation-rule type="required" message="{{'errors.namerequired'|translate}}"></dxi-validation-rule>
      </dxi-column>
  
      <dxi-column type="buttons">
        <dxi-button name="edit" text="{{'tableinfo.editbutton'|translate}}"></dxi-button>
        <dxi-button name="delete" text="{{'tableinfo.removebutton'|translate}}"></dxi-button>
      </dxi-column>
      <dxo-remote-operations [groupPaging]="true">
      </dxo-remote-operations>
    </dx-data-grid>
  </div>