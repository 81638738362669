import { NgModule } from '@angular/core';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { AppInfoService, AuthService, ScreenService } from '../shared/services';
import { HttpErrorHandler } from '../shared/services/http-error-handler.service';
import { genericCustomStoreService } from '../shared/services/genericCustomStore.service';
import { MessageService } from '../shared/services/message.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../interceptor/auth.interceptor';
import { HeaderInterceptor } from '../interceptor/header.interceptor';
import { ErrorInterceptor } from '../interceptor/error.interceptor';
import { CacheInterceptorInterceptor } from '../interceptor/cache-interceptor.interceptor';



@NgModule({
  providers:[
    AuthService,
    ScreenService,
    AppInfoService,
    HttpErrorHandler,
    genericCustomStoreService,
    MessageService,
    DatePipe, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },

    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HeaderInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    // {
    //   provide:HTTP_INTERCEPTORS,
    //   useClass:CacheInterceptorInterceptor,
    //   multi: true
    // }
    // {provide: APP_BASE_HREF, useValue: 'aya'}
  ]
})
export class CoreModule { }
