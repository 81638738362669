
<ng-container *ngIf="isAuthenticated(); else unauthenticated">
  <app-side-nav-inner-toolbar title="{{appInfo.title}}">
      <router-outlet></router-outlet>
    <app-footer>
     
      <br/>
      جميع الحقوق محفوظة - Pioneers Solutions 2024
    </app-footer>
  </app-side-nav-inner-toolbar>
</ng-container>

<ng-template #unauthenticated>
  <app-unauthenticated-content></app-unauthenticated-content>
</ng-template>



